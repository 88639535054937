import {BrowserModule, provideClientHydration} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, withFetch, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {ToastrModule} from 'ngx-toastr';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ShopComponent} from './shop/shop.component';
import {PagesComponent} from './pages/pages.component';
import {ElementsComponent} from './elements/elements.component';
import {CommonModule, IMAGE_CONFIG, IMAGE_LOADER, ImageLoaderConfig} from '@angular/common';
import {InterceptService} from './core';
import {AuthService} from './modules/auth/services/auth.service';
import {environment} from '../environments/environment';
import {PopupCookiesComponent} from 'ngx-cookies-preference';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {clearStateMetaReducer} from './core/ngrx/stores/reducers/clear-state-meta-reducer';
import {accountDataFeature} from './core/ngrx/stores/features/account-data-features';
import {navigationFeatures} from './core/ngrx/stores/features/navigation-features';
import {userInitializedFeatures} from './core/ngrx/stores/features/user-initializedFeatures';
import {uploadingFileOrderFeatures} from './core/ngrx/stores/features/uploading-file-order-features';
import {assistanceFeatures} from "./core/ngrx/stores/features/assistance-features";

function appInitializer(authService: AuthService) {
    return () => new Promise((resolve) => {
        //    @ts-ignore
        authService.getUserByToken().subscribe().add(resolve);
    });
}

@NgModule({ declarations: [
        AppComponent,
        ShopComponent,
        PagesComponent,
        ElementsComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        NgbModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            progressBar: false,
            enableHtml: true,
        }),
        TranslateModule.forRoot(),
        SharedModule,
        AppRoutingModule,
        PopupCookiesComponent,
        StoreModule.forRoot({}, { metaReducers: [clearStateMetaReducer] }),
        StoreModule.forFeature(accountDataFeature),
        StoreModule.forFeature(navigationFeatures),
        StoreModule.forFeature(userInitializedFeatures),
        StoreModule.forFeature(uploadingFileOrderFeatures),
        StoreModule.forFeature(assistanceFeatures),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        })],
    providers: [
        InterceptService,
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService],
        },
        {
            provide: IMAGE_CONFIG,
            useValue: {
                placeholderResolution: 40,
                disableImageSizeWarning: true
            }
        },
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig) => {
                return `${environment.addressPressupBackoffice}${config.src}`;
            }
        },
        provideClientHydration(),
    ] })
export class AppModule {
}
