<ngx-loading-bar></ngx-loading-bar>
<router-outlet style="position: absolute"></router-outlet>
<app-tap-to-top></app-tap-to-top>
<app-whatsapp-contact></app-whatsapp-contact>

@if (isBrowser) {
    <popup-cookies [configPopupCookies]="configPopupCookies"></popup-cookies>
}
<ng-container *ngIf="visibleLayoutBox">
    <app-layout-box></app-layout-box>
</ng-container>
