export const locale = {
    lang: 'it',
    data: {
        HOME: {
            SHIPPING_FREE: 'Spedizione gratuita',
            ASSISTANCE24H: 'Assistenza 24h',
            SHIPPING_FAST: 'Spedizione Rapida',
            BEST_SELLERS: 'I più venduti',
            FIRST_ORDER_PRESENT: 'Il regalo sul tuo primo ordine',
            DISCOUNT_COUPON: '25€ DI BUONO SCONTO',
            HAVEN_T_YET_FOUND: 'Non hai ancora trovato quello che cercavi?',
            DESIGN_TOGETHER: 'Progettiamo insieme',
            SAY_ABOUT_US: 'Dicono di noi',
        },
        NEWSLETTER: {
            PRESENT: 'Il tuo regalo di benvenuto',
            DISCOUNT_COUPON: 'sul tuo primo ordine',
            REGISTER_AND_SUBSCRIBE: 'Registrati e iscriviti alla newsletter',
            START_NOW: 'Inizia ora'
        },
        GENERAL: {
            OK: 'Ok',
            DELETE: 'Elimina',
            NEXT: 'Avanti',
            CONFIRM_CANCEL: 'Conferma/Annulla',
            CONFIRM_NEW_FILE: 'Conferma/Nuovo file',
            CANCEL: 'Annulla',
            RETRY: 'Riprova',
            CLICK_HERE: 'Clicca qui',
            INSERT: 'Inserisci',
            ADD: 'Aggiungi',
            SAVE: 'Salva',
            NO_PREDICTIONS: 'Nessun suggerimento',
            NOT_FOUND: 'Nessun risultato',
            SUCCESS: 'Successo',
            EXIT: 'Esci',
            CONFIRM: 'Conferma',
            VERIFY: 'Verifica',
            UNDO: 'Annulla',
            BACK: 'Indietro',
            CONTINUE: 'Continua',
            SUBSCRIBE: 'Iscriviti',
            SUBMIT_BUTTON: 'Invia',
            CALL: 'Chiama',
            DATA_ACCOUNT: 'Dati account',
            ENTER_YOUR_EMAIL: 'Inserisci la tua e-mail',
            ENTER_YOUR_NAME: 'Inserisci il nome',
            ENTER_YOUR_SURNAME: 'Inserisci il cognome',
            ENTER_YOUR_PHONE: 'Inserisci il telefono',
            ENTER_YOUR_IDEA: 'La tua idea',
            ENTER_TEXT: 'Inserisci il testo',
            CAPTCHA_ERROR: 'Il testo inserito non è corretto',
            CAPTCHA_MANY_ERROR: 'Hai esaurito i tuoi tentativi, ricarica la pagina!',
            ENTER_YOUR_RECIPIENT: 'Inserisci il destinatario',
            ENTER_YOUR_DESCRIPTION: 'Inserisci descrizione',
            ENTER_YOUR_PROVINCE: 'Inserisci la provincia',
            ENTER_ADDRESS: 'Inserisci indirizzo',
            ENTER_TAX_ID_CODE: 'Inserisci il codice fiscale',
            ENTER_CAP: 'Inserisci cap',
            ENTER_YOUR_COUNTRY: 'Inserisci paese',
            SELECT_LOCATION: 'Inserisci località',
            SELECT_SOCIETY: 'Seleziona il tipo di società',
            SELECT_SECTOR: 'Seleziona settore',
            ENTER_P_IVA: 'Inserisci P.IVA',
            ENTER_BUSINESS_NAME: 'Inserisci ragione sociale',
            ENTER_PEC: 'Inserisci email PEC',
            ENTER_EMAIL: 'Inserisci email',
            SELECT_EVENT: 'Seleziona evento',
            SELECT_OPTION: 'Seleziona opzione',
            ENTER_CODE: 'Inserisci codice univoco',
            GENERAL_SELECT: 'Seleziona',
            ITALIAN: 'Italiano',
            ENGLISH: 'Inglese',
            ALMOST_THERE: 'Ci siamo quasi',
            COUPONS: 'Buoni sconto',
            FIND_OUT_MORE: 'Scopri di più',
            NOTIFICATION_MANAGEMENT: 'Gestione notifiche',
            UPDATE_PASSWORD: 'Modifica password',
            COOKIE_POLICY: 'Cookie policy',
            PRIVACY_POLICY: 'Privacy policy',
            DETAIL: 'Dettaglio',
            ACTIONS: 'Azioni',
            DONT_SHOW: 'Non mostrare più',
            HOME: 'home',
            GO_HOME: 'Vai alla home',
            HELLO: 'Ciao',
            INSTRUCTION: 'Istruzioni',
            ERRORS: {
                GENERIC_ERROR: 'Errore, non è stato possibile eseguire l\'operazione',
                ERROR_DOWNLOAD_DATA: 'Dati scaricati non correttamente',
                IMAGE: 'Immagine non caricata correttamente',
                FILE: 'File non caricato correttamente',
                PAGE_NOT_FOUND: 'Pagina non trovata',
                IMAGE_DIMENSION: 'La dimensione del file risulta superiore a 2 MB',
                IMAGE_DIMENSION_AZURE: 'La dimensione del file risulta superiore a 1 GB',
                RESOLUTION: 'La risoluzione del file inserito non è corretta',
                IMAGE_FORMAT: 'Il formato del file non è supportato',
                ERROR_REORDER_DATA: 'Dati non riordinati correttamente',
                MAX_NUMBER_OF_FILE_REACHED: 'Limite di file inseriti raggiunto',
                NO_DATA_FOUND: 'Nessun dato trovato',
                MAX_SIZE_CONSOLE_ERROR: 'Il file supera la dimensione massima di 1 GB.'
            },
            ERROR: 'Errore',
            COMPLETED: 'Completato',
            CANCELLED: 'Annullato',
            WATING: 'In Attesa',
            IN_PROGRESS: 'In Lavorazione',
            PAID: 'Pagato',
            NOT_PAID: 'Non Pagato',
            PRINT_FILES_LOADED: 'File Di Stampa Caricati',
            PRINT_FILES__NOT_LOADED: 'File Di Stampa Non Caricati',
            SHIPPED: 'Spedito',
            READY_FOR_DELIVERY: 'Pronto Per La Consegna',
            PENDING_PAYMENT: 'In Attesa Di Pagamento',
            TO_BE_CONFERMED: 'Da Confermare',
            VERIFIED_FILE_PRINT_ORDER: 'File Verificato - Ordine In Stampa',
            PACKING: 'Imballo',
            UNDER_CONSTRUCTION: 'In Allestimento',
            FILE_WAITING: 'Attesa File',
            VERIFICATION_VAT_4: 'Verifica Cert.IVA 4%',
            WAITING_FOR_NEW_FILE: 'Attesa Nuovo File',
            PRINTING_TEST: 'Prova Di Stampa',
            IN_PRODUCTION: 'In Produzione',
            IN_PRESS: 'In Stampa',
            PAGE: 'Pagina',
            OF: 'di',
            DROP_IMAGE_VIEW: 'Visualizza',
            DROP_IMAGE_UPLOAD: 'Carica',
            DROP_IMAGE_ALREADY_VIEW: 'File già caricato',
            DROP_IMAGE_DELETE: 'Cancella',
            LABEL_REQUIRED: 'Campo obbligatorio',
            VALID_FORMAT: 'Formato valido:'
        },
        UPLOADING_FILE: {
            DRAG_DEFAULT: 'Trascina il file da caricare o clicca qui',
            DRAG: 'Trascina il file da caricare o clicca qui per selezionarlo dalla tua libreria',
            DRAG_1: 'Per lavorazioni con fronte e retro diversi puoi inserire un unico file PDF contenente entrambe le facciate su pagine diverse o due file distinti(PDF,TIFF,JPG) con le due facciate.',
            DRAG_2: 'Per lavorazioni di riviste o libri devi inviare due file distinti in PDF, uno per la copertina ed uno per le pagine interne.',
            DRAG_3: 'Per lavorazioni di riviste auto copertinate inviare un unico file PDF contenente tutte le pagine compresa la copertina.',
            DRAG_4: 'Non è possibile caricare cartelle compresse (ZIP, RAR, 7z, ecc.).'
        },
        NOTIFICATION_MANAGEMENT: {
            EVENT: 'Evento',
            EMAIL: 'Email',
            NEW_NOTIFICATION: 'Nuova notifica'
        },
        ADDRESS: {
            DEFAULT: 'Indirizzo classico',
            BARTOLINI_ADDRESSES: 'Fermo deposito presso filiare Bartolini',
            NEW_SHIPPING_ADDRESS: 'Nuovo indirizzo di spedizione',
            MODIFY_SHIPPING_ADDRESS: 'Modifica indirizzo di spedizione',
            NEW_BILLING_ADDRESS: 'Nuovo indirizzo di fatturazione',
            MODIFY_BILLING_ADDRESS: 'Modifica indirizzo di fatturazione',
        },
        CASHBACK: {
            TITLE: 'Programma cashback',
            SECTION_TITLE: 'Il programma cash back',
            HOW_IT_WORKS: 'Come funziona',
            HOW_IT_WORKS_DESCRIPTION: 'PressUP emetterà nel mese un BUONO SCONTO, calcolato moltiplicando l\'ammontare della spesa effettuata nel mese precedente' +
                'su tutti i prodotti di PressUP per la % indicata in tabella. L\'ammontare della spesa mensile è calcolato in base al valore dell\'ordine indicato in fattura, al netto di IVA.',
            BOX: {
                TITLE_1: 'Per tutti i clienti',
                TITLE_2: 'Ordina e ricevi uno sconto',
                TITLE_3: 'Cumulabile con altre offerte',
                TEXT_1: 'Il programma Cash Back è riservato a tutti i clienti e si applica a tutti gli ordini effettuati online (www.pressup.it)',
                TEXT_2: 'La possibilità di ricevere un BUONO SCONTO proporzionale alla spesa che hai effettuato nel mese precedente su tutti i prodotti di PressUP',
                TEXT_3: 'Può essere utilizzato  su tutti i prodotti del catalogo ed è cumulabile con altre offerte e promozioni',
            },
            CARDS: {
                TITLE_1: 'Buono sconto del <span class="color-default">2,5%</span>',
                TITLE_2: 'Buono sconto del <span class="color-default">5%</span>',
                TITLE_3: 'Buono sconto del <span class="color-default">7,5%</span>',
                TITLE_4: 'Buono sconto del <span class="color-default">10%</span>',
                TEXT_1: 'Per una spesa tra 1€ e 499€',
                TEXT_2: 'Per una spesa tra 500€ e 1499€',
                TEXT_3: 'Per una spesa tra 1500€ e 4999€',
                TEXT_4: 'Per una spesa maggiore 5000€'
            },
            FAQS: {
                TITLE_1: 'Cosa è il programma cashback?',
                TITLE_2: 'A chi è riservato?',
                TITLE_3: 'Quali sono gli ordini che vengono considerati per il calcolo del buono sconto?',
                TITLE_4: 'Dove posso vedere l\ammontare del mio buono spesa?',
                TITLE_5: 'Quali sono le caratteristiche del buono sconto?',
                TEXT_1: 'La possibilita\' di ricevere un BUONO SCONTO proporzionale alla spesa che hai effettuato nel mese precedente su tutti i prodotti di PressUP',
                TEXT_2: 'Il programma Cash Back è riservato a tutti i clienti e si applica a tutti gli ordini effettuati online (tramite la piattaforma pressup.it) nel mese precedente su tutti i prodotti di PressUP. Sono esclusi gli ordini inseriti offline (telefono, mail, messaggistica, etc.) dal servizio clienti.',
                TEXT_3: 'Ai fini del calcolo dell’ammontare della spesa mensile, saranno presi in considerazione gli ordini creati entro la fine del mese precendente e che soddisfano i seguenti requisiti:\n' +
                    '<ul><li>ordini in carta di credito o bonifico anticipato pagati entro la fine del mese precendente</li>' +
                    '<li>ordini in contrassegno spediti entro il mese precendente</li></ul>' +
                    'Sono considerati tutti gli ordini effettuati online (tramite la piattaforma pressup.it) nel mese precedente su tutti i prodotti di PressUP. Sono esclusi gli ordini inseriti offline (telefono, mail, messaggistica, etc.) dal servizio clienti.\n' +
                    'L\'ammontare della spesa risultante sara\' moltiplicato per la % percentuale indicata in tabella ottenendo cosi l\'importo del BUONO SCONTO.',
                TEXT_4: 'Nella tua area personale, nella sezione CashBack, potrai consultare l\'ammontare speso, il buono maturato del mese corrente e i buoni sconto maturati nei mesi precendenti.',
                TEXT_5: '<ul><li>È valido per 60 giorni a partire dalla data di emissione</li>' +
                    '<li>Puo’ essere utilizzato su tutti i prodotti del catalogo</li>' +
                    '<li>È cumulabile con offerte e promozioni</li>' +
                    '<li>Non È cumulabile con altri codici sconto</li>' +
                    '<li>Non è frazionabile</li></ul>'
            },
            HEADERS: {
                CODE: 'Codice',
                DESCRIPTION: 'Descrizione',
                VALUE: 'Valore',
                RESIDUE: 'Residuo',
                MINIMUM_SPENDING: 'Spesa minima',
                VALIDITY_START_DATE: 'Data inizio validità',
                EXPIRY_DATE: 'Data scadenza'
            },
            NO_ELEMENTS_TO_SHOW: 'Nessun buono attualmente disponibile'
        },
        ASSISTANCE: {
            TITLE: 'Assistenza',
            NEW_REPORT: 'Nuova segnalazione',
            HEADERS: {
                INSERTION_DATE: 'Data inserimento',
                PROCESSING_NUMBER: 'Numero lavorazione',
                STATE: 'Stato',
                DESCRIPTION: 'Descrizione'
            },
            STEPS: {
                FIND_PROCESSING: 'Trova \n lavorazione',
                FIND_PROCESSING_INLINE: 'Trova lavorazione',
                PROBLEM_DESCRIPTION: 'Descrizione \n del problema',
                PROBLEM_DESCRIPTION_INLINE: 'Descrizione del problema',
                SOLUTION: 'Soluzione',
            },
            DETAIL: {
                ORDER: 'Ordine',
                SUMMARY: 'Riepilogo',
                N_COPIES: 'Numero di copie riguardante la segnalazione',
                PRODUCT: 'Prodotto',
                SOLUTION: 'Soluzione',
                PROPOSED_SOLUTION: 'Soluzione proposta',
                PROBLEMS_OF_SIGNALLING: 'Problematiche della segnalazione ',
                ADDITIONAL_NOTES: 'Note aggiuntive',
                PHOTO_PROBLEMS: 'Foto del problema',
                BACK: 'Indietro'
            },
            STEP_1: {
                HEADER: 'Inserisci il numero della lavorazione',
                N_WORKER: 'N° lavorazione',
                INSERT_N_WORKER: 'Inserisci il numero della lavorazione',
                NAME_WORKER: 'Nome della lavorazione',
                INSERT_NAME_WORKER: 'Inserisci nome della lavorazione',
                TOTAL_WORKER: 'La segnalazione riguarda l\'intera lavorazione',
                N_COPIES_WORKER: 'La segnalazione riguarda il seguente numero di copie',
                N_COPIES: 'Numero copie',
                INSERT_N_COPIES: 'Inserisci il numero copie',
                REPORT_ALREADY_MADE: 'Segnalazione già effettuata. Contatta l\'assistenza per ulteriori info.',
                ORDER_TO_DELIVERY: 'Il tuo ordine è in fase di consegna. Contatta l\'assistenza per ulteriori info.'
            },
            STEP_2: {
                REASONS_FOR_REPORTING: 'Motivo della segnalazione',
                SELECT_REPORTING: 'Seleziona uno o più problematiche sulla lavorazione',
                MY_FILE_NO_USED: 'Non è stato usato il mio file',
                WRONG_SIZE: 'Dimensioni errate',
                SALTATETRANSPARENT: 'Saltate trasparente',
                DOUBLE_SIDED: 'Bifacciale stampato solo su fornte',
                UNREGISTERED: 'Fuori registro',
                WRONG_COLOR: 'Colore erratto',
                DIFFERENT_MATERIAL: 'Materiale diverso',
                MISSING_COPIES: 'Copie mancanti',
                SPOTS_HALOS: 'Macchie/aloni',
                MISCUT: 'Taglio errato',
                MISSTRUCTURE: 'Struttura errata',
                INCORRECT_MOUNTING: 'Montaggio errato',
                MISSING_PIECES: 'Pezzi mancanti',
                DAMAGED_GOODS: 'Merce danneggiata',
                DELAY: 'Ritardo',
                NON_MINE: 'Merce non mia',
                OTHER_REASON: 'Altro motivo',
                NOTE: 'Note',
                DESCRIBE_NOTE: 'Descrivi il motivo della segnalazione',
                UPLOAD_PHOTO: 'Carica foto del problema (max 4)',
                ADD_FILE: 'Aggiungi file',
                ADD_PDF: 'Aggiungi pdf',
                PREVIEW: 'Anteprima',
                FILE_NAME: 'Nome file',
                ACTION: 'Azioni',
            },
            STEP_3: {
                PROPOSE_SOLUTION: 'Proponi soluzione',
                REPINT: 'Ristampa le copie',
                OTHER_SOLUTION: 'Altra soluzione',
                PROPOSE_A_SOLUTION: 'Proponici una soluzione',
                WRITE_SOLUTION: 'Scrivici una soluzione...'
            },
            STEP_4: {
                REPORT_SUCCESS: 'Segnalazione inviata correttamente'
            }
        },
        ORDERS: {
            ORDERS: 'Ordini',
            ORDER: 'Ordine',
            TOTAL: 'Totale',
            SEARCH_PRODUCT: 'Cerca prodotto...',
            ORDER_DATA: 'Data ordine',
            NEW_ORDER: 'Ordina di nuovo',
            DOWNLOAD_INVOCE: 'Scarica fattura',
            NAME_PROCESSING: 'Nome lavorazione',
            NAME_PROC: 'Nome lavoraz.',
            SHIPPING: 'Spedizione',
            SHIPPINGS: 'Spedizioni',
            ADDRESS_SHIPPING: 'Indirizzo di spedizione',
            VIEW_ALL: 'VEDI TUTTO...',
            PAY_NOW: 'Paga ora',
            UPLOAD_FILE: 'Carica file',
            SEARCH_PLACEHOLDER: 'Cerca ordine...',
            NO_ORDERS: 'Nessun ordine effettuato',
            CONFIRM_PDF_TITLE: 'Conferma pdf in visione',
            CONFIRM_PDF_DESCRIPTION: 'Conferma il tuo ordine utilizzando il file che ti abbiamo inviato in visione o caricando un nuovo file',
            CONFIRM_PDF_BTN: 'Conferma Pdf in Visione',
            UPLOAD_NEW_FILE: 'Carica nuovo ' +
                '\n' +
                'file',
            CONFIRM_CANCEL_ORDER: 'Conferma o annulla ordine',
            CONFIRM_CANCEL_SUB_TITLE: 'Conferma il tuo ordine utilizzando il file caricato precedentemente o caricando un nuovo file, altrimenti annulla il tuo ordine',
            CANCEL_ORDER: 'Annulla ordine',
            CONFIRM_FILE_OR_PRINT_TRIAL: 'Conferma file o prova di stampa',
            NO_ORDER_ADDED: 'Nessun ordine aggiunto',
            DISCOVER_CATALOGUE: 'Sfoglia il catalogo e aggiungi i prodotti al tuo carrello',
            FILE_TO_UPLOAD: 'File da caricare'
        },
        ORDER_DETAIL: {
            GO_TO_ORDER: 'Torna agli ordini',
            ORDER_AGAIN: 'ordina di nuovo',
            ORDER: 'Ordine',
            ORDER_ON: 'Ordinalto il',
            EXPECTED_DELIVERY: 'Consegna prevista',
            NAME_PROCESSING: 'Nome lavorazione',
            PROCESSING: 'Lavorazioni',
            SHIPPING: 'Spedizioni',
            PAYMENT_METHOD: 'Metodo di pagamento',
            EMAIL: 'E-mail',
            PAYMENT_ON: 'Pagato il',
            VIEW_INVOICE: 'Vedi fattura',
            TOT_SHIPPING: 'Tot. spedizione',
            TOT_PROCESSING: 'Tot. lavorazioni',
            TOT_VAT_EXCLUDED: 'Tot. IVA esclusa',
            WITH_VAT: 'Con IVA',
            BONUS_IMPORT: 'Importo buono regalo',
            TOTAL: 'Totale',
            DISCOVER_MORE: 'Mostra di più',
            DISCOVER_LESS: 'Mostra meno',
            SHOWING: 'Ordini mostrati',
            OF: 'di',
            RESULTS: 'risultati',
            ORDER_ITEM_DETAIL: 'Dettaglio ordine',
            EXTRA: 'Extra',
            DISCOUNT: 'Sconto'
        },
        PROCESS_DETAIL: {
            ORDER_CHARACTERISTICS: 'Caratteristiche ordine (lavorazioni)',
            NUMBER_OF_COPIES: 'Numero copie',
            RETRO_COLOR: 'Colore retro',
            EXTRA_NOTE: 'Note extra',
            PRINT_FORMAT: 'Formato stampa',
            PAPER: 'Carta',
            BINDING: 'Rilegatura',
            FOREHEAD_COLOR: 'Colore fronte',
            GRAMMAGE: 'Grammatura',
            FOLDING: 'Piegatura',
            INTERNAL_PLASTIFICATION: 'Plastificazione interna',
            ADMINISTRATIVE_INFORMATION: 'Informazioni amministrative',
            SHIPPING_PRICE: 'Prezzo spedizione',
            NET_PRICE: 'Prezzo netto',
            VAT: 'Iva',
            PAYMENT: 'Pagamento',
            AMOUNT_CARD: 'Importo da pagare carta',
            SHIPPING_INFORMATION: 'Informzioni spedizione',
            FORESEE_SHIPPING: 'Prevista spedizione',
            SHIPPING: 'Spedizione',
            ANONYMOUS_SHIPPING: 'Spedizione anonima',
            EXTRA: 'Extra'
        },
        ORDER_FILE: {
            PRINT_INFORMATION: 'Informazione per la stampa',
            UPLOADING_FILE: 'Caricamento file',
            ASSOCIATE_WORK_FILE: 'Associazione file lavoro:',
            REAL_FORMAT: 'Formato effettivo di stampa',
            REAL_FORMAT_ADVICE: '(se diverso da quello ordinato es. ordinato 21x29,7 ma richiesto 20x28)',
            ABUNDANCE: 'Abbondanza',
            NUM_SUBJECT: 'Numero soggetti',
            SAME_SUBJECT: 'Stesso soggetto',
            MULTI_SUBJECT: 'Soggetti multipli',
            MULTI_SUBJECT_DISABLED: 'Divisione soggetti multipli disabilitata per questo ordine',
            MULTI_SUBJECT_DISABLED_MAX_STACK: 'Limite soggetti multipli raggiunto per questa lavorazione',
            NOTIFY: 'La selezione prevede un supplemento di prezzo',
            ADD_SUBJECT: 'Aggiungi',
            MULTI_SUBJECT_PARTITION: 'Suddivisione soggetti multipli',
            REMAIN_COPIES: 'copie rimanenti',
            ERROR_OVERFLOW: 'Il numero copie totali deve essere: ',
            INSERT_REMAIN_COPIES: 'Inserisci copie rimanenti',
        },
        CART: {
            EMPTY_CART: 'Il tuo carrello è attualmente vuoto.',
            TITLE_EMPTY_CART: 'Il tuo carrello è vuoto',
            SUBTITLE_EMPTY_CART: 'Aggiungi qualcosa per rendermi felice :)',
        },
        INVOICE: {
            ADDRESS_1: 'Via Cavour, 155b - 00045',
            ADDRESS_2: 'Roma (RM) - IT',
            ADDRESS_3: 'P.IVA IT 10922761001',
            BILING_ADRESS: 'Indirizzo di fatturazione',
            N_BILLING: 'N° fattura',
            N_ORDER: 'N° ordini',
            AMOUNT_DUE: 'Importo dovuto',
            DESCRIPTION: 'Descrizione',
            COPIES: 'Copie',
            TOTAL: 'Totale',
            PAYED_WITH: 'Pagato con',
            TOT_NET: 'Tot. netto',
            VAT: 'IVA',
            TOT_ORDER: 'Tot.ordine',
            DOWNLOAD_PDF: 'Download come pdf',
            PRINT: 'Stampa',
            DATE_OF_ISSUE: 'Data di emissione',
        },
        ERRORS_MAP: {
            REQUIRED: 'É obbligatorio',
            TOO_SHORT: 'Troppo corta',
            INVALID: 'É invalido',
            NOT_NUMBER: 'Non è un numero',
            WRONG_LENGTH: 'Lunghezza errata',
            INVALID_TYPE: 'Tipo invalido',
            ALREADY_USED: 'Già usato',
            VAT_NOT_VALID: 'P.IVA invalida',
            TAX_ID_NOT_VALID: 'Codice fiscale non valido',
            BUSINESS_NAME_NOT_VALID: 'Ragione sociale non valida',
            PEC_NOT_VALID: 'Pec invalida',
            UNIQUE_CODE_NOT_VALID: 'Codice univoco invalido',
        },
        HEADER: {
            PLACEHOLDER_SEARCH: 'Cosa vuoi stampare?',
            NO_RESULT: 'Nessun risultato',
            YOUR_BROWSER_NOT_SUPPORT_VOICE_RECOGNITION: 'Il tuo browser non supporta il riconoscimento vocale'
        },
        ACCOUNT_DATA: {
            CURRENT_PASSWORD: 'Password attuale',
            NEW_PASSWORD: 'Nuova password',
            CONFIRM_PASSWORD: 'Conferma nuova password',
            ENTER_CURRENT_PASSWORD: 'Inserisci password attuale',
            ENTER_NEW_PASSWORD: 'Inserisci nuova password',
            ENTER_CONFIRM_PASSWORD: 'Ripeti nuova password'
        },
        AUTH: {
            ABOUT_US: {
                TITLE_SECTION_2: 'Il tuo servizio di stampa digitale e offset online',
                TEXT_1: 'Attiva sul mercato dal 2010, PressUP è una tech-company pura che offre l\'eccellenza nel campo della stampa online, combinando tecnologie digitali e offset.' +
                    'Un solution provider globale che fonda la propria crescita esponenziale sulla combinazione virtuosa di know-how tipografico, concept innovativo della vendita online di stampati e minuzioso controllo qualità lungo tutta la filiera.' +
                    'Tra gli asset aziendali, il customer care consulenziale, l’attenzione alla sostenibilità ambientale e i servizi a valore aggiunto come PressUP NOW che garantisce la consegna in giornata.',
                TEXT_2: 'Attiva sul mercato dal 2010, PressUP è una tech-company pura che offre l\'eccellenza nel campo della stampa online, combinando tecnologie digitali e offset.' +
                    'Un solution provider globale che fonda la propria crescita esponenziale sulla combinazione virtuosa di know-how tipografico, concept innovativo della vendita online di stampati e minuzioso controllo qualità lungo tutta la filiera.' +
                    'Tra gli asset aziendali, il customer care consulenziale, l’attenzione alla sostenibilità ambientale e i servizi a valore aggiunto come PressUP NOW che garantisce la consegna in giornata.'
            },
            SUSTAINABLE_PRINTING: {
                CERT_FSC: 'Certificazione FSC',
                CERT_PEFC: 'Certificazione PEFC',
                OF_COURSE_PAPER: 'Naturalmente io ♡ la carta'
            },
            GENERAL: {
                LOGIN: 'Accedi',
                WELCOME_TO_CASH_NOVA: 'Benvenuto su CASH NOVA',
                OR: 'O',
                SUBMIT_BUTTON: 'Invia',
                NO_ACCOUNT: 'Non hai un accaunt?',
                SIGNUP_BUTTON: 'Iscrizione',
                FORGOT_BUTTON: 'Password dimenticata',
                BACK_BUTTON: 'Indietro',
                PRIVACY: 'Privacy',
                LEGAL: 'Legal',
                CONTACT: 'Contatti',
                LOADING_CONTENT: 'Stiamo recuperando i tuoi dati...',
                LOGOUT: 'Sei sicuro di voler effettuare il logout?'
            },
            LOGIN: {
                LOGIN: 'Login',
                EMAIL: 'Email',
                PASSWORD: 'Password',
                INSERT_PASSWORD: 'Inserisci password',
                STAY_CONNECTED: 'Resta connesso',
                FORGOT_PASSWORD: 'Password dimenticata',
                OR_SIGN_IN: 'oppure accedi con',
                NEW_USER: 'Nuovo utente',
                CREATE_ACCOUNT: 'Crea il tuo account',
                REGISTER_TEXT_1: 'Clicca "Registrati" , inserisci i dati richiesti e puoi iniziare gli acquisti.',
                REGISTER_TEXT_2: 'Iscriviti alla newsletter per approffittare di 25€ di sconto sul tuo primo ordine.',
                REGISTER: 'Registrati',
                PASSWORD_RECOVERY: 'Recupero password',
                REGISTRATION: 'Registrazione'
            },
            FORGOT: {
                TITLE: 'Password dimenticata?',
                DESC: 'Inserisci l\'e-mail con cui ti sei registrato',
                SUCCESS: 'Il tuo account è stato reimpostato con successo.'
            },
            RECOVERY_PASSWORD: {
                CREATE_PASSWORD: 'Crea nuova password',
                CONSTRAINTS: 'La password deve contenere almeno: un carattere maiuscolo, un carattere minuscolo, un numero, un carattere speciale, deve essere di almeno 8 caratteri.',
                SAVE: 'Salva'
            },
            REGISTER: {
                EMAIL: 'Email',
                PASSWORD: 'Password',
                INSERT_PASSWORD: 'Inserisci password',
                CONFIRM_PASSWORD: 'Conferma Password',
                NEWS_LETTER_SUBSCRIBE: `Chiedo l\'iscrizione alla newsletter per ricevere lo <b>&nbsp;sconto di € 25</b>`,
                ACCEPT_CONDITION: 'Accetto le condizioni sul trattamento dei dati personali',
                CREATE_ACCOUNT: 'Crea il tuo account',
                REGISTER: 'Registrati'
            },
            FOOTER: {
                SUBSCRIBE_THE_NEWSLETTER: 'Iscriviti alla newsletter',
                DATA_NOT_SEND: 'I tuoi dati non verranno comunicati a terzi',
                ENTER_YOUR_EMAIL: 'Inserisci la tua e-mail',
                ACCEPT_PRIVACY: 'Accetto la normativa sulla privacy',
                VAT_NUMBER_FISCAL_CODE: 'Partita IVA / Codice fiscale',
                SHIPMENTS: 'Spedizioni',
                GENERAL_CONDITIONS_OF_SALE: 'Condizioni generali di vendita',
                ABOUT_US: 'Chi siamo',
                FILE_GUIDELINES: 'Linee guida file',
                CONTACTS: 'Contatti',
                SUSTAINABILITY: 'Sostenibilità',
                PRINT: 'Stampa',
                PRINTING_TECHNIQUES: 'Tecniche di stampa',
                SUSTAINABLE_PRINTING: 'Stampa sostenibile'
            },
            FIRST_ACCESS: {
                SUBTITLE: 'Inserisci Partita IVA e numero di telefono\ndel rappresentante legale per continuare.',
                VAT: 'P. IVA',
                VAT_PLACEHOLDER: 'Inserisci la partita IVA',
                LEGAL_NUMBER: 'Telefono del rappr. legale',
                LEGAL_NUMBER_PLACEHOLDER: 'Inserisci il numero di telefono',
            },
            OTP_CODE: {
                INSERT_CODE: 'Inserisci codice',
                INSERT_CODE_GENERATED: 'Inserisci il codice OTP generato dall\'app di autenticazione',
                CODE: 'Codice',
                WRONG_CODE: 'Codice errato. Inserire un nuovo codice'
            },
            INPUT: {
                EMAIL: 'Email',
                FULLNAME: 'Nome completo',
                PASSWORD: 'Password',
                USERNAME: 'Username'
            },
            FORGOT_PASSWORD: {
                FORGOT: 'Password dimenticata ?',
                ENTER_EMAIL_FOR_RECOVERY_PASSWORD: 'Inserisci la tua email per modificare la password',
                ENTER_YOUR_EMAIL: 'Inserisci la tua e-mail'
            },
            VALIDATION: {
                PASSWORD_NOT_EQUALS: 'Password e conferma password non corrispondono',
                INVALID: '{{name}} non è valido',
                INVALID_FEMALE: '{{name}} è invalida',
                REQUIRED: '{{name}} è richiesto',
                MIN_LENGTH: '{{name}} la lunghezza minima è {{min}}',
                AGREEMENT_REQUIRED: 'È necessario accettare termini e condizioni',
                NOT_FOUND: 'La richiesta {{name}} non è stata trovata',
                INVALID_LOGIN: 'I dettagli di accesso non sono corretti',
                REQUIRED_FIELD: 'Campo obbligatorio',
                MIN_LENGTH_FIELD: 'Lunghezza minima del campo:',
                MAX_LENGTH_FIELD: 'Lunghezza massima del campo:',
                INVALID_FIELD: 'Campo non valido',
                BIRTH_DAY_DIVERGENT_FROM_CFIS: 'Data divergente da quella presente nel codice fiscale',
                INVALID_COUNTRY: 'Il paese selezionato non è valido',
                INVALID_DATE: 'Data non valida'
            },
            ERRORS: {
                CAPTCHA_ERR: 'Errore nel recaptcha, riprova tra qualche istante.',
                LOGIN_ERROR: 'Errore durante il login',
                FIRST_ACCESS_ERROR: 'Errore primo accesso',
                WALLET_ONBOARDING_NOT_PRESENT: 'Wallet onboading non presente',
                NO_ACCOUNT_DETAILS: 'Impossibile recuperare i dettagli dell\' utente'
            }
        },
        ECOMMERCE: {
            COMMON: {
                SELECTED_RECORDS_COUNT: 'I record selezionati sono: ',
                ALL: 'Tutti',
                SUSPENDED: 'Sospeso',
                ACTIVE: 'Attivo',
                FILTER: 'Filtro',
                BY_STATUS: 'per Stato',
                BY_TYPE: 'per Tipo',
                BUSINESS: 'Business',
                INDIVIDUAL: 'Individuale',
                SEARCH: 'Cerca',
                IN_ALL_FIELDS: 'in tutti i campi'
            },
            ECOMMERCE: 'eCommerce',
            CUSTOMERS: {
                CUSTOMERS: 'Clienti',
                CUSTOMERS_LIST: 'Lista clienti',
                NEW_CUSTOMER: 'Nuovo Cliente',
                DELETE_CUSTOMER_SIMPLE: {
                    TITLE: 'Eliminazione cliente',
                    DESCRIPTION: 'Sei sicuro di eliminare definitivamente questo cliente?',
                    WAIT_DESCRIPTION: 'Il cliente sta eliminando...',
                    MESSAGE: 'Il cliente è stato eliminato'
                },
                DELETE_CUSTOMER_MULTY: {
                    TITLE: 'Elimina cliente',
                    DESCRIPTION: 'Sei sicuro di eliminare definitivamente i clienti selezionati?',
                    WAIT_DESCRIPTION: 'I clienti stanno eliminando...',
                    MESSAGE: 'I clienti selezionati sono stati eliminati'
                },
                UPDATE_STATUS: {
                    TITLE: 'Lo stato è stato aggiornato per i clienti selezionati',
                    MESSAGE: 'Lo stato dei clienti selezionati è stato aggiornato con successo'
                },
                EDIT: {
                    UPDATE_MESSAGE: 'Il cliente è stato aggiornato',
                    ADD_MESSAGE: 'Il cliente è stato creato'
                }
            }
        },
        PAGES: {
            GENERIC_CATEGORY: {
                BANNER_TITLE: 'Non hai trovato ciò che cerchi?',
                BANNER_SUBTITLE: 'Compila il form, aggiungi le caratteristiche che preferisci e richiedi un preventivo personalizzato. Riceverai una risposta nel più breve tempo possibile.',
                BANNER_BTN_TITLE: 'Richiedi un preventivo personalizzato',
                RELATED_PRODUCTS: 'Prodotti correlati',
                LEARN_MORE: 'Approfondisci'
            },
            PROFILE: {
                TITLE: 'Profilo',
                ACCOUNT_DATA: 'Dati account',
                HOW_KNOW_US: 'Come ci hai conosciuto?',
                SHIPPING_ADDRESSES: 'Indirizzi di spedizione',
                SHIPPING_ADDRESS: 'Indirizzo di spedizione',
                BILLING_ADDRESS: 'Indirizzo di fatturazione',
                BILLING_ADDRESSES: 'Dati di fatturazione',
                BILLING_ADDRESSES_SUB_TITLE: 'Una volta salvati i dati di fatturazione non è possibile modificarli',
                ORDERS: 'Ordini',
                ARCHIVED_ORDERS: 'Ordini archiviati',
                CASHBACK: 'Cashback',
                NOTIFICATION_MANAGEMENT: 'Gestione notifiche',
                ASSISTANCE: 'Assistenza',
                UPDATE_PASSWORD: 'Modifica password',
                LOG_OUT: 'Log-out',
                ADD_ADDRESS: 'Aggiungi indirizzo',
                UPDATE_ADDRESS: 'Modifica indirizzo',
                ADD_NOTIFICATION: 'Aggiungi notifica',
                NO_NOTIFICATION: 'Nessuna notifica aggiunta al momento',
                NOTIFICATION_PAGINATION: 'Visualizzazione da {first} a {last} di {totalRecords} voci',
                SHIPPING_ADDRESSES_DATA: {
                    DEFAULT_ADDRESS: 'Indirizzo predefinito',
                    SET_DEFAULT_ADDRESS: 'Imposta come predefinito',
                    MOBILE: 'Telefono'
                }
            },
            SOCIETY: {
                NAME: 'Società',
                SECTOR: 'Settore',
                P_IVA: 'P.IVA',
                PHYSICAL_PERSSON: 'Persona fisica',
                ASSOCIATION: 'Associazione',
                INDIVIDUAL_COMPANY: 'Ditta individuale',
                SIMPLE_COMPANY: 'Simple company',
                BUSINESS_NAME: 'Ragione sociale',
                UNIQUE_CODE: 'Codice univoco',
            },
            STATIC_PAGES: {
                GENERAL_CONDITIONS_SALE: {
                    TITLE: 'Condizioni generali di vendita'
                },
                COOKIE_POLICY: {
                    TITLE: 'Cookies Policy di PressUp',
                    CONTENT: '<b>PressUp</b> utilizza cookie per fornire servizi che migliorino l’esperienza di navigazione\n' +
                        'degli utenti.<br><br>' +
                        'Come utilizziamo i cookie:<br>' +
                        '<ul><li>Utilizziamo cookie di navigazione anonimi, necessari per consentire agli utenti di\n' +
                        'navigare correttamente su <b>PressUp</b>, utilizzare tutte le funzionalità ed accedere ad aree\n' +
                        'riservate.</li>' +
                        '<li> Utilizziamo cookie analitici per capire meglio come i nostri utenti utilizzano il sito web,\n' +
                        'per ottimizzare e migliorare il sito, rendendolo sempre interessante e rilevante per gli\n' +
                        'utenti.</li></ul>' +
                        'Queste informazioni sono anonime, e utilizzate solo a fini statistici.\n' +
                        'Utilizziamo i cookie commerciali anonimi per mostrarti le nostre pubblicità su altri siti\n' +
                        'web, in base ai prodotti che hai visualizzato nelle navigazioni precedenti. Questi cookie\n' +
                        'vengono solitamente inseriti da parti terze di fiducia (come Google attraverso il\n' +
                        'remarketing per la pubblicità display), tuttavia, PressUp non consente a tali società di\n' +
                        'raccogliere informazioni personali relative agli utenti. Ciò significa che sarà garantito\n' +
                        'l\'anonimato e i dati personali non verranno usati da altri siti web o database di marketing.\n' +
                        'Come modificare o disattivare le preferenze dei cookie<br>' +
                        '<ul><li>Dalle impostazioni del tuo browser (Internet Explorer, Safari, Firefox, Chrome, ecc.)' +
                        'potrai stabilire quali sono i cookie che desideri ricevere/mantenere e quali no. Per' +
                        'sapere dove puoi trovare suddette impostazioni, utilizza la guida del tuo browser.</li>' +
                        '<li>I visitatori possono disattivare Google Analytics per la pubblicità display e personalizzare gli annunci della Rete Display di Google utilizzando la Gestione' +
                        'preferenze annunci.</li> '
                },
                CONTACT: {
                    VIDEO_CALL: 'Fissa una video call',
                    SUBTITLE_VIDEO_CALL: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
                    SEND: 'Invia',
                    FORM: {
                        NAME: 'Nome',
                        ENTER_NAME: 'Inserisci il nome',
                        SURNAME: 'Cognome',
                        ENTER_SURNAME: 'Inserisci il cognome',
                        PHONE_NUMBER: 'Numero di telefono',
                        ENTER_PHONE_NUMBER: 'Inserisci il numero di telefono',
                        EMAIL: 'E-mail',
                        ENTER_EMAIL: 'Inserisci l\'e-mail',
                        MESSAGE: 'Messaggio',
                        ENTER_MESSAGE: 'Inserisci il tuo messaggio'
                    },
                    CHAT: 'Chat',
                    SOCIAL: 'Social'
                },
                ESTIMATE: {
                    TITLE: 'Preventivo personalizzato',
                    SUB_TITLE: 'Compila il form con le caratteristiche del lavoro desiderato, riceverai una risposta nel più breve tempo possibile',
                    FORM_ESTIMATE_REQUEST: {
                        TITLE: 'Richiedi un preventivo',
                        JOB_NAME: 'Nome lavoro',
                        NUMBER_OF_COPIES: 'Numero di copie',
                        JOB_TYPOLOGY: 'Tipologia di lavoro',
                        PRODUCT: 'Prodotto',
                        CLOSED_FORMAT: 'Formato chiuso',
                        OPENED_FORMAT: 'Formato aperto',
                        MEASURE_STRAPS: 'Misura bandelle',
                        NUMBER_OF_PAGES_WITH_COVER: 'Numero facciate con copertina',
                        PRINT_COLORS_OR_B_N: 'Stampa (colori o B/N)',
                        COVER: 'Copertina (colori o B/N)',
                        INTERNAL_PAPER_TYPE: 'Tipo carta interno',
                        COVER_PAPER_TYPE: 'Tipo carta copertina',
                        BINDING: 'Rilegatura',
                        LAMINATION: 'Plastificazione',
                        DESCRIPTION: 'Descrizione',
                        PLACEHOLDER_JOB_NAME: 'Inserisci nome lavoro',
                        PLACEHOLDER_NUMBER_OF_COPIES: 'Inserisci numero di copie',
                        PLACEHOLDER_JOB_TYPOLOGY: 'Seleziona tipologia di lavoro',
                        PLACEHOLDER_PRODUCT: 'Seleziona prodotto',
                        PLACEHOLDER_CLOSED_FORMAT: 'Inserisci formato chiuso',
                        PLACEHOLDER_OPENED_FORMAT: 'Inserisci formato aperto',
                        PLACEHOLDER_MEASURE_STRAPS: 'Inserisci misura bandelle',
                        PLACEHOLDER_NUMBER_OF_PAGES_WITH_COVER: 'Inserisci numero facciate con copertina',
                        PLACEHOLDER_PRINT_COLORS_OR_B_N: 'Seleziona stampa',
                        PLACEHOLDER_COVER: 'Seleziona copertina',
                        PLACEHOLDER_INTERNAL_PAPER_TYPE: 'Inserisci tipo carta interno',
                        PLACEHOLDER_COVER_PAPER_TYPE: 'Inserisci tipo carta copertina',
                        PLACEHOLDER_BINDING: 'Inserisci rilegatura',
                        PLACEHOLDER_LAMINATION: 'Inserisci plastificazione',
                        PLACEHOLDER_DESCRIPTION: 'Inserisci descrizione',
                    },
                    FORM_CONTACT_REQUEST: {
                        TITLE: 'Contatto',
                        NOMINATIVE: 'Nominativo',
                        PLACEHOLDER_NOMINATIVE: 'Inserisci nominativo',
                        PHONE: 'Telefono',
                        PLACEHOLDER_PHONE: 'Inserisci telefono',
                        EMAIL: 'E-mail',
                        SEND_REQUEST: 'Invia richiesta'
                    }
                },
                PRIVACY: {
                    TITLE: 'Allegato alle condizioni<br> generali di contratto',
                    SUBTITLE: 'INFORMATIVA SUL TRATTAMENTO DATI PERSONALI',
                    CONTENT: 'Ai sensi dell’art. 13 del decreto legislativo 196 del 30 giugno 2003 “Codice in materia di protezione dei dati personali”, il Cliente come designato nelle Condizioni Generali di' +
                        'contratto è informato e, per quanto di ragione, espressamente consente: <br>' +
                        'a) che i dati forniti per lo svolgimento della istruttoria preliminare e i dati relativi allo svolgimento del rapporto contrattuale vengano trattati dalla Press Up s.r.l. con sede in' +
                        'Via E.Q. Visconti n. 90 - 00193 Roma iscritta presso la Camera di Commercio di Roma al n.1265559 del Registro delle imprese, CF n.10922761001 partita IVA n.10922761001\n' +
                        'di seguito indicata come “Press Up S.r.l.”, indirizzo di posta elettronica certificata\n' +
                        'pressup@legalmail.it per finalità di: <br>' +
                        '- adempimento di obblighi legali, ivi inclusi gli obblighi di identificazione, conservazione<br>' +
                        'e segnalazione previsti dalle normative antiriciclaggio (D.lgs. 56/2004);<br>' +
                        '- gestione dei rapporti contrattuali ed in particolare delle obbligazioni di cui ai contratti\n' +
                        'di vendita, quali ad esempio la spedizione dei plichi all’indirizzo di residenza, la\n' +
                        'fatturazione, la stampa dei materiali consegnati direttamente dal Cliente;<br>' +
                        '- elaborazione statistica;<br>' +
                        '- tutela e recupero dei crediti;<br>' +
                        '- marketing, informazione commerciale, pubblicità, manifestazioni a premi, rilevazione\n' +
                        'del grado di soddisfazione della clientela (il conferimento dei dati per tale ultima finalità\n' +
                        'è facoltativo e il loro utilizzo è subordinato al consenso dell’interessato) mediante\n' +
                        'i) elaborazione elettronica;<br>' +
                        'ii) consultazione;<br>' +
                        'iii) l’utilizzo di sistemi automatizzati di chiamata, senza l’intervento di un operatore,<br>' +
                        'posta anche elettronica, telefax e messaggi di tipo MMS (Multimedia Message Service) e SMS (Short Message Service);<br>' +
                        '- ogni altra opportuna operazione relativa al conseguimento delle predette finalità, anche\n' +
                        'per mezzo dei seguenti soggetti ai quali i dati possono essere comunicati (anche all\'estero\n' +
                        'in Paesi appartenenti e non appartenenti all\'Unione Europea) ed in particolare: 1) di\n' +
                        'soggetti specificamente incaricati quali consulenti, dipendenti e altri collaboratori a ciò\n' +
                        'abilitati per i trattamenti necessari o connessi allo svolgimento delle attività precontrattuali (istruttoria, valutazione dell’affidabilità finanziaria, ecc.) ed all’esecuzione\n' +
                        'del contratto; <br><br>' +
                        '2) di terzi che svolgono o forniscono specifici servizi strettamente funzionali\n' +
                        'all’esecuzione del rapporto contrattuale (anche mediante trattamenti continuativi), quali:<br>' +
                        '- società di servizi informatici;<br>' +
                        '- banche e società che svolgono servizi di pagamento;<br>' +
                        '- assicurazioni;<br>' +
                        '- organismi associativi propri del settore della tipografia e stampa nonché a tutti gli altri\n' +
                        'soggetti ai quali la comunicazione sia dovuta per il raggiungimento di finalità ed obblighi\n' +
                        'di legge;<br>' +
                        '- prestatori di assistenza, società controllate o collegate a Press Up s.r.l. o dalla stessa\n' +
                        'incaricate, in Italia o all’estero per il raggiungimento delle finalità o altre compagnie di\n' +
                        'assicurazione e/o società di servizi per la distribuzione del rischio;<br>' +
                        '- subappaltatori incaricati;<br>' +
                        '- agenti;<br>' +
                        '- società di factoring;' +
                        '- società di recupero crediti;<br>' +
                        '- soggetti che forniscono informazioni commerciali;<br>' +
                        '- avvocati o commercialisti;<br>' +
                        'b) che i predetti dati possono essere raccolti sia presso l’interessato sia presso terzi;<br>' +
                        'c) che il conferimento dei dati richiesti, siano essi acquisiti in base ad un obbligo di legge\n' +
                        'ovvero in quanto strettamente funzionali all’esecuzione del rapporto contrattuale, è\n' +
                        'obbligatorio e che un eventuale rifiuto di fornirli comporta l’impossibilità di svolgere le\n' +
                        'attività richieste per la conclusione e per l’esecuzione del contratto e segnatamente con\n' +
                        'riferimento alla conclusione ed esecuzione del contratto on line.\n' +
                        'In relazione al trattamento dei predetti dati il Cliente, in base all’art. 7 del citato d.lgs.\n' +
                        '196/2003, ha il diritto di ottenere dalla Press Up S.r.l.: '
                },
                FAQS: {
                    TABS: {
                        GENERAL_INFO: 'Info Generali',
                        ACCOUNT_MANAGEMENT: 'Gestione account',
                        ORDER_CREATION: 'Creazione ordine',
                        ORDER_MANAGEMENT: 'Gestione ordine',
                        SHIPMENTS: 'Spedizioni',
                        ADMINISTRATION: 'Amministrazione',
                        CASHBACK_PROGRAM: 'Programma cashback'
                    },
                    GENERAL_INFO: {
                        TITLE_1: 'Qual è la differenza tra offerta è promozione?',
                        TITLE_2: 'POSSO UTILIZZARE L\'OFFERTA "PROVACI A 1€"?',
                        TITLE_3: 'AVETE UN CAMPIONARIO DELLE VOSTRE CARTE?',
                        TITLE_4: 'COME POSSO OTTENERE UN PREVENTIVO?',
                        TITLE_5: 'HO BISOGNO DI INDICAZIONI PER LA COSTRUZIONE DEI FILE DA CARICARE PER LA STAMPA: DOVE LE TROVO?',
                        TITLE_6: 'POSSO RICHIEDERE UN SUPPORTO PER L\'ELABORAZIONE GRAFICA?',
                        TITLE_7: 'POSSO PUBBLICARE IL MARCHIO FSC® SUL MIO STAMPATO?',
                        TEXT_1: 'L\'offerta è il miglior prezzo disponibile sul sito per alcune combinazioni prodotto/caratteristiche, ottenuto grazie all\'ottimizzazione dei processi sulla relativa lavorazione. Le promozioni sono invece ribassi di prezzo che vengono applicati su uno o più prodotti per un periodo limitato di tempo.',
                        TEXT_2: 'Puoi approfittare dell\'offerta solo se hai partita IVA e non hai mai effettuato ordini su pressup.it. Accedi alla sezione specifica e segui le indicazioni riportate sul sito.',
                        TEXT_3: 'Sì, puoi richiedere il nostro campionario carte, al costo di 10€ + IVA, inviando una e-mail ad assistenza@pressup.it.',
                        TEXT_4: 'Sul sito, accedendo alla pagina del prodotto che ti interessa, puoi visualizzare in tempo reale e senza alcun impegno il prezzo (per singolo soggetto di stampa) in base alle caratteristiche impostate e al tempo di consegna selezionato. Ti consigliamo di effettuare l\'accesso per visualizzare il prezzo corretto.\n' +
                            'Se navigando sul sito non hai trovato quello che cerchi, puoi richiedere un preventivo personalizzato tramite il link in basso nella pagina o inviando una e-mail all\'indirizzo preventivi@pressup.it',
                        TEXT_5: 'Sul sito, nella sezione Guida per la costruzione del file, puoi trovare le principali linee guida per la costruzione dei file. Inoltre, accedendo alla pagina del prodotto che ti interessa, hai la possibilità di scaricare il template per la costruzione del file per la stampa dell\'ordine. Per ulteriori dubbi ti invitiamo a contattare il nostro servizio clienti.',
                        TEXT_6: 'Su specifica richiesta, possiamo occuparci dell\'elaborazione grafica per il tuo ordine:\n' +
                            '<ul><li>Creazione abbondanze sul file di stampa</li>' +
                            '<li>Controllo alta risoluzione del file</li>' +
                            '<li>Per il piccolo formato, in caso di ricezione di loghi e testi, creazione del file di stampa</li>' +
                            '<li>Per le cartelline, posizionamento della corretta fustella sulla grafica inviata</li></ul>' +
                            'Terminata l\'elaborazione ti invieremo via e-mail il pdf in visione da approvare per procedere con la stampa.\n' +
                            'Il servizio di elaborazione grafica prevede un costo aggiuntivo a partire da 10€+IVA. Ti suggeriamo di inviarci una e-mail all\'indirizzo ordini@pressup.it prima di effettuare l\'ordine così ti forniremo tutte le indicazioni necessarie.',
                        TEXT_7: 'La pubblicazione del marchio FSC® deve essere autorizzata dall\'Ente Certificatore. Per essere approvato, il logo deve avere le seguenti misure:' +
                            '<ul><li>Logo “Standard” orizzontale: altezza 12 mm</li>' +
                            '<li>Logo “Minilab” orizzontale: altezza 8 mm.</li></ul>' +
                            'Assicurati quindi che il logo soddisfi questi requisiti e invia una e-mail ad ordini@pressup.it, allegando il file .pdf della pagina in cui deve essere posizionato. Il nostro servizio clienti inoltrerà la richiesta all\'Ente e te ne comunicherà l\'esito.'
                    },
                    ACCOUNT_MANAGEMENT: {
                        TITLE_1: 'POSSO MODIFICARE L\'INDIRIZZO E-MAIL DI REGISTRAZIONE AL SITO?',
                        TITLE_2: 'POSSO MODIFICARE I DATI DEL MIO ACCOUNT?',
                        TEXT_1: 'Per modificare l\'indirizzo di registrazione, invia una e-mail ad assistenza@pressup.it, indicando la nuova e-mail da sostituire. Nel caso in cui il nuovo indirizzo sia già presente sui nostri sistemi sarai contattato dal nostro servizio clienti.',
                        TEXT_2: 'Per modificare i dati del tuo account, invia una e-mail ad assistenza@pressup.it.\n' +
                            'Ti ricordiamo che non puoi modificare partita IVA e codice fiscale: in tal caso è necessario registrare un nuovo account.'
                    },
                    ORDER_CREATION: {
                        TITLE_1: 'DEVO REGISTRARMI AL SITO PER FARE UN ORDINE?',
                        TITLE_2: 'COME POSSO EFFETTUARE UN ORDINE?',
                        TITLE_3: 'QUALI SONO LE MODALITA\' DI PAGAMENTO DELL\'ORDINE?',
                        TITLE_4: 'E\' POSSIBILE AVERE DELLE MODALITA\' DI PAGAMENTO DIVERSE RISPETTO A QUELLE PREVISTE SUL SITO?',
                        TITLE_5: 'DOVE TROVO LE INFORMAZIONI PER EFFETTUARE IL PAGAMENTO TRAMITE BONIFICO?',
                        TITLE_6: 'STO FACENDO UN ORDINE: PERCHE\' IL PAGAMENTO IN CONTRASSEGNO E\' DISABILITATO?',
                        TITLE_7: 'POSSO FARE UN UNICO ORDINE PER PIU\' PRODOTTI?',
                        TITLE_8: 'POSSO FARE UN UNICO ORDINE PER UNO STESSO PRODOTTO CON PIU\' SOGGETTI?',
                        TITLE_9: 'POSSO RICHIEDERE UNA PROVA DI STAMPA?',
                        TITLE_10: 'QUANDO E COME POSSO CARICARE I FILE PER LA STAMPA?',
                        TITLE_11: 'POSSO DUPLICARE UN ORDINE?',
                        TEXT_1: 'Sì, è necessario registrare un account per poter effettuare un ordine ed accedere ai servizi offerti. Clicca su Registrati in alto a destra ed inserisci le informazioni richieste.',
                        TEXT_2: 'Sul sito, accedendo con il tuo accounti alla pagina del prodotto che ti interessa, puoi visualizzare in tempo reale e senza alcun impegno il prezzo (per singolo soggetto di stampa) in base alle caratteristiche impostate e al tempo di consegna selezionato. Ti consigliamo di effettuare l\'accesso per visualizzare il prezzo corretto. Se navigando sul sito non hai trovato quello che cerchi, puoi richiedere un preventivo personalizzato tramite il link in basso nella pagina o inviando una e-mail all\'indirizzo preventivi@pressup.it',
                        TEXT_3: 'Puoi scegliere tra diversi metodi di pagamento:' +
                            '<ul><li>Carta di credito: Visa, Mastercard, Paypal e American Express</li>' +
                            '<li>Contrassegno:pagamento in contanti o con assegno bancario direttamente al corriere al momento della consegna</li>' +
                            '<li>Bonifico bancario anticipato</li></ul>' +
                            'Tutti i metodi di pagamento sono privi di commissioni.',
                        TEXT_4: 'Le modalità di pagamento sono solo quelle riportate sul sito.',
                        TEXT_5: 'Le informazioni sono riportate in basso nella mail di riepilogo che ricevi a conferma dell\'ordine effettuato.\n' +
                            'Per comodità le riportiamo di seguito:' +
                            'Press Up s.r.l.' +
                            'Banca Unicredit' +
                            'Bic: UNCRITM1D80' +
                            'IBAN: IT78C0200873190000401451375',
                        TEXT_6: 'Il pagamento in contrassegno non è disponibile per i prodotti in offerta.',
                        TEXT_7: 'Non gestiamo il carrello, pertanto è necessario effettuare un ordine per ciascun prodotto desideri acquistare. In caso di pagamento tramite bonifico bancario, puoi effettuare un pagamento unico per tutti gli ordini registrati, indicando nella causale il relativo numero di riferimento.',
                        TEXT_8: 'Il multisoggetto è previsto solo su alcuni prodotti con un costo aggiuntivo di 5€ + IVA a soggetto. Ti suggeriamo di contattare il nostro servizio clienti prima di effettuare l\'ordine così verificheremo la fattibilità della richiesta e, in caso positivo, ti forniremo tutte le indicazioni necessarie.',
                        TEXT_9: 'Puoi richedere la prova di stampa su Libri e Cataloghi a partire da 30€ + IVA: puoi effettuare la richiesta direttamente da sito in fase di ordine nella sezione di caricamento file. Ti ricordiamo che la prova di stampa serve a verificare la correttezza dell\'impaginazione, della sequenza delle pagine e la tipologia di carta, mentre non deve essere utilizzata come campione colore. Per tutti gli altri prodotti la prova di stampa non è disponbile, ma puoi selezionare una quantità minima e quindi richiedere un numero ridotto di copie. Per maggiori informazioni ti invitiamo a contattare il nostro servizio clienti.',
                        TEXT_10: 'Dopo aver inserito le caratteristiche del prodotto scelto, una volta confermato l\'ordine, ti viene richiesto di caricare i file relativi alla lavorazione scelta. È possibile caricare i file anche in un secondo momento accedendo alla sezione \'I miei ordini\'.',
                        TEXT_11: 'Se vuoi duplicare un ordine, devi necessariamente effetuarne uno nuovo dal sito, selezionando le stesse caratteristiche del precedente. Se l\'ordine da duplicare è relativo all\'anno in corso o al precedente, in fase di creazione del nuovo ordine, puoi richiedere nel campo Note di utilizzare gli stessi file di stampa dell\'ordine precedente, indicandone il numero, e di inviarti un pdf in visione prima di procedere con la stampa. Per qualsiasi dubbio ti invitiamo a contattare il nostro servizio clienti.'
                    },
                    ORDER_MANAGEMENT: {
                        TITLE_1: 'DA QUANDO PARTE LA LAVORAZIONE DEL MIO ORDINE?',
                        TITLE_2: 'POSSO ANTICIPARE O POSTICIPARE UN ORDINE?',
                        TITLE_3: 'POSSO ANNULLARE UN ORDINE?',
                        TITLE_4: 'POSSO MODIFICARE UN ORDINE?',
                        TITLE_5: 'A CHE PUNTO E\' IL MIO ORDINE?',
                        TEXT_1: 'La lavorazione dell\'ordine viene avviata dal momento in cui riceviamo i file ideonei alla stampa e, in caso di pagamento con carta di credito o bonifico, l\'accredito effettivo dell\'importo dell\'ordine sul nostro conto corrente: in caso di pagamento con bonifico anticipato, è necessario pertanto considerare almeno 2 giorni lavorativi in più rispetto alla data di consegna indicata.',
                        TEXT_2: 'Dato l\'elevato livello di automazione del nostro sistema, non è possibile intervenire per modificare le tempistiche di un ordine già entrato nel flusso delle lavorazioni. Ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta.',
                        TEXT_3: 'Puoi annullare il tuo ordine solo se la lavorazione non è stata avviata. Ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta. Naturalmente se hai scelto una forma di pagamento anticipato (carta di credito o bonifico), ti rimborseremo l\'importo versato: il rimborso sarà effettuato tramite bonifico bancario sull\'IBAN che ti sarà richiesto al momento dell\'annullamento dell\'ordine.',
                        TEXT_4: 'Se devi modificare alcune caratteristiche del tuo ordine, ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta.',
                        TEXT_5: 'Puoi seguire il tuo ordine dall’area riservata alla sezione "I miei ordini" verificando in ogni momento il suo stato. Inoltre, per ordini spediti, il giorno della spedizione il corriere ti invierà via e-mail il numero di tracking.'
                    },
                    SHIPMENTS: {
                        TITLE_1: 'L\'INDIRIZZO DI SPEDIZIONE PUO\' ESSERE DIVERSO DA QUELLO DI FATTURAZIONE?',
                        TITLE_2: 'POSSO MODIFICARE L\'INDIRIZZO DI SPEDIZIONE DI UN ORDINE GIA\' EFFETTUATO?',
                        TITLE_3: 'POSSO INDICARE PIU\' INDIRIZZI DI SPEDIZIONE SULL\'ORDINE?',
                        TITLE_4: 'EFFETTUATE SPEDIZIONI ALL\'ESTERO?',
                        TEXT_1: 'Sì, sia in fase di registrazione puoi impostare due indirizzi differenti per fatturazione e spedizione, sia in fase di compilazione dell\'ordine puoi modificare l\'indirizzo di spedizione, purché resti all\'interno del paese di fatturazione.',
                        TEXT_2: 'Per ordini non ancora affidati al corriere, possiamo effettuare la modifica; in caso contrario non sempre è possibile ed è necessario inoltrare la richiesta al corriere. Ti suggeriamo di contattare il nostro servizio clienti per verificare l\'effettivo stato dell\'ordine e valutare la fattibilità della richiesta.',
                        TEXT_3: 'Sì. Per ordini con pagamento in carta di credito o bonifico, puoi inserire in fase di ordine più indirizzi di spedizione, riportando per ciascuno le relative quantità da spedire. Il costo della multispedizione è di 12€ + IVA per ciascuna spedizione aggiuntiva. In caso di pagamento in contrassegno, ti suggeriamo di contattare il nostro servizio clienti per valutare la fattibilità della richiesta ed aggiornare l\'ordine.',
                        TEXT_4: 'Sì, in caso di spedizione all\'estero è necessario richiedere un preventivo personalizzato tramite l\'apposito link presente sul sito o inviando una e-mail all\'indirizzo preventivi@pressup.it.',
                    },
                    ADMINISTRATION: {
                        TITLE_1: 'QUALI SONO I TEMPI PER OTTENERE UN RIMBORSO?\n',
                        TITLE_2: 'POTETE EMETTERE FATTURA CON SPLIT PAYMENT?',
                        TITLE_3: 'POTETE EMETTERE FATTURA CON IVA AGEVOLATA AL 4%?',
                        TITLE_4: 'DOVE E QUANDO SONO DISPONIBILI LE FATTURE?',
                        TITLE_5: 'COME POSSO MODIFICARE L\'INTESTAZIONE DELLA FATTURA?',
                        TEXT_1: 'I rimborsi vengono effettuati entro la prima settimana del mese successivo all\'approvazione della richiesta. Il rimborso avviene tramite bonifico bancario sull\'IBAN che ti sarà richiesto dal nostro servizio clienti o dalla nostra amministrazione al momento dell\'apertura della pratica di rimborso. Dove prevista, sarà contestualmente emessa la relativa nota di credito.',
                        TEXT_2: 'Sì, se sei soggetto allo split payment, su specifica richiesta, possiamo emettere fattura con applicazione della scissione dei pagamenti. Ti suggeriamo di inviarci una e-mail all\'indirizzo amministrazione@pressup.it ordini@pressup.it una volta inserito l\'ordine e prima di effettuare il pagamento, così ti forniremo tutte le indicazioni necessarie.',
                        TEXT_3: 'Sì, su specifica richiesta, sia per la stampa di riviste e volumi editoriali sia per la stampa di materiale elettorale.\n' +
                            'Ti suggeriamo di inviarci una e-mail all\'indirizzo ordini@pressup.it una volta inserito l\'ordine e prima di effettuare il pagamento: ti verrà inoltrata una apposita dichiarazione da compilare.\n' +
                            'La nostra policy aziendale riconosce l\'applicazione dell\'iva al 4% su prodotti editoriali qualora vengano riscontrate le seguenti condizioni:\n' +
                            '<ul><li>Qualifica di editore (registrazione presso il Tribunale o esercizio dell\'attività editoriale riscontrabile dal CODICE ATECO indicato in Visura Camerale, o nello Statuto qualora si tratti di associazione)</li>' +
                            '<li>Riviste: testata di copertina regolarmente depositata presso il Tribunale</li>' +
                            '<li>Codice ISBN per la distribuzione del proprio volume editoriale.</li></ul>' +
                            'Effettuate le opportune verifiche, aggiorneremo l\'aliquota iva dell\'ordine.',
                        TEXT_4: 'Tutte le fatture vengono emesse in formato elettronico. La copia di cortesia viene inoltrata via e-mail all\'indirizzo di registrazione e caricata sul portale nella tua area riservata quando l\'ordine risulta spedito.\n' +
                            'Relativamente alle tempistiche di emissione:\n' +
                            '<ul><li>Se il pagamento viene effettuato con bonifico bancario o con carta di credito, le fatture vengono emesse contestualmente alla data di accredito del pagamento<li>' +
                            '<li>Se l\'ordine viene saldato in contrassegno, la fattura viene emessa contestualmente alla spedizione dell\'ordine</li></ul>',
                        TEXT_5: 'La fattura viene emessa secondo i dati inseriti in fase di registrazione. Se si vuole intestare la fattura ad un soggetto diverso dal cliente che ha inserito l\'ordine, è necessario creare un nuovo account e inoltrare la richiesta ad assistenza@pressup.it con il nuovo indirizzo mail e richiedendo l\'associazione tra account e ordine.'
                    }
                },
                PRINTING_TECHNIQUES: {
                    KOMORI_GL840P_H_UV: '' +
                        '- Innovativo sistema di essicazione rapida H-UV  \n' +
                        '                            - Stampa Offset H-UV 8 colori\n' +
                        '                            - Asciugatura immediata dello stampato\n' +
                        '                            - Incredibile brillantezza dei colori\n' +
                        '                            - Alto grado di lucido della vernice H-UV\n' +
                        '                            - Cambio lastre simultaneo\n' +
                        '                            - Stampa su supporti plastici e sintetici\n' +
                        '                            - Formato 72x103 cm\n' +
                        '                            - 15.000 fogli/h',
                    HEIDELBERG_XL_105: '- Alta flessibilità e produttivita\n' +
                        '                            - Eccezionale qualità di stampa fino a 400 g\n' +
                        '                            - Unità di veniciatura Flexo\n' +
                        '                            - Stampa offset 6 colori\n' +
                        '                            - Formato 75x105 cm\n' +
                        '                            - 18.000 fogli/h',
                    HP_INDIGO_12000: '- Ecosostenibilità (disinchiostrazione e riciclabilità ' +
                        '                            dell\'inchiostro HP lndigo Electrolnk)\n' +
                        '                            - Stampa Digitale Offset a 7 colori (CMYK+3 Pantoni/Esacromia+Pantone)\n' +
                        '                            - Stampa con inchiostro Bianco\n' +
                        '                            - Inchiostri per effeti speciali (trasparente, goffratura, rilievo)\n' +
                        '                            - Stampa di dati variabili (testo ed immagini a colori)',
                    HP_INDIGO_7900: '- Ecosostenibilità (disinchiostrazione e riciclabilità ' +
                        '                            dell\'inchiostro HP lndigo Electrolnk)\n' +
                        '                            - Stampa Digitale Offset a 7 colori ' +
                        '                            (CMYK+3 Pantoni/Esacromia+Pantone)\n' +
                        '                            - Stampa con inchiostro Bianco\n' +
                        '                            - Inchiostri per effeti speciali ' +
                        '                            (trasparente, goffratura, rilievo)\n' +
                        '                            - Stampa di dati variabili (testo ed immagini a colori)',
                },
                DESIGN_YOUR_IDEA: {
                    TITLE: 'Progettiamo la tua idea',
                    DESIGN_TOGETHER: 'Progettiamo insieme',
                    DESIGN_TOGETHER_SUBTITLE: 'Mettiamo a tua disposizione i nostri consulenti per aiutarti a trasformare le tue idee in realtà',
                    TELL_US_IDEA: 'Raccontaci la tua idea',
                    TELL_US_IDEA_SUBTEXT: 'Compila il form e dai un titolo alla tua idea.\n' +
                        'Un nostro consulente ti contatterà per ascoltare le tue esigenze',
                    WE_PRESENT_OUR_PROJECT: 'Ti presentiamo il nostro progetto',
                    WE_PRESENT_OUR_PROJECT_SUBTEXT: 'Il consulente condivide con te il progetto\n' +
                        'fornendoti modalità e i tempi di realizzazione.',
                    YOUR_IDEA_BECOMES_REALITY: 'La tua idea diventa realtà',
                    YOUR_IDEA_BECOMES_REALITY_SUBTEXT: 'Approvi il progetto e noi lo realizziamo',
                    WHAT_WE_CAN_DO: 'Cosa possiamo fare',
                    WHAT_WE_CAN_DO_SUBTITLE: 'Mettiamo a tua disposizione i nostri consulenti per aiutarti a trasformare le tue idee in realtà',
                    CONSULTANT_DEDICATED: 'Un consulente dedicato',
                    DESIGN: 'Progettazione',
                    GRAPHIC_CREATION: 'Creazione grafica',
                    PROTOTYPING: 'Prototipazione',
                    PRESS: 'Stampa',
                    VISIBILITY: 'Visibilità sui nostri canali',
                    SET_UP_VCALL: 'Fissa una video call',
                    CONTACT_US: 'Contattaci',
                    CONTACT_US_TEXT: 'PressUP: il partner ideale per la stampa dei tuoi prodotti. In PressUP puoi trovare tantissimi supporti per la realizzazione dei tuoi progetti. Ti offriamo un servizio di stampa online e comodo. Scegli il prodotto, spiega la tua idea ai nostri grafici esperti, scegli la proposta grafica che preferisci e stampa i tuoi nuovi articoli promozionali personalizzati!'
                },
                PRESS_UP_NOW: {
                    TITLE: 'PRESS UP NOW',
                    DESCRIPTION: 'Per te che stampi all\'ultimo minuto è arrivato PressUP Now!\n' +
                        'Il primo servizio di stampa e consegna in giornata.',
                    CAP_COVERED: 'Verifica se il tuo CAP è coperto dal servizio NOW',
                    SERVICE_CHECK: 'Verifica disponibilità del servizio',
                    PRODUCT: 'Il servizio pressup now è disponibile per i seguenti prodotti:',
                    BUSINESS_CARD: 'Biglietti da visita',
                    FLYERS: 'Volantini',
                    FOLDABLE: 'Pieghevi',
                    POSTERS: 'Locandine',
                    STAPLED_CATALOGUES: 'Cataloghi con punto metallico',
                    POSTCARDS: 'Cartoline',
                    LETTERHEAD: 'Carta intestata',
                    MACHINE_SHEETS: 'Fogli macchina'
                },
                GUIDELINES: {
                    TITLE: 'Linee guida pressup',
                    TABS: {
                        GENERAL_INFO: 'Info generali',
                        COLOR_SETTING: 'Impostazioni sui colori',
                        FOLDER_AND_FOLDING_SETTING: 'Impostazioni cartelline & pieghevoli',
                        ACCORDION: {
                            TITLE_1: 'Checklist File',
                            TEXT_1: '<p>Prima di inviarci il file grafico, assicurati di aver spuntato correttamente tutte i punti di questa lista, per avere la sicurezza che il tuo prodotto venga stampato alla massima qualità.</p>' +
                                '<p>Per maggiori informazioni su ognuno degli elementi, leggi le relative sezioni di questa pagina.</p>' +
                                '<p>Checklist:</p>' +
                                '<ul class="p-style ms-3 mb-2"><li>Il formato finale e il numero delle pagine corrispondono al prodotto che hai ordinato sul nostro sito?</li>' +
                                '<li>Il file grafico include 5 mm di margini, e 5 mm di abbondanza?</li>' +
                                '<li>Il file grafico è impostato sul modello quadricromia CMYK?</li>' +
                                '<li>Gli elementi nelle tonalità del grigio sono configurati correttamente come scala di solo Nero (C, M, Y = 0%)?</li>' +
                                '<li>Stai usando il tono composito di nero (K=100%, and C=30%) per grandi aree ed elementi neri?</li>' +
                                '<li>Le linee sottili (meno di 2 punti) e i testi sottili (meno di 8 punti) sono impostati ad un solo colore?</li>' +
                                '<li>I font che non sono convertiti in curve sono inseriti nel file grafico?</li>' +
                                '<li>Il file grafico è salvato come PDF/X-3:2002 con un\'abbondanza di 5 mm?</li></ul>' +
                                '<p>Se hai spuntato tutti gli elementi su questa lista, sei pronto a consegnare il tuo file grafico.</p>' +
                                '<p>Congratulazioni!</p>',
                            TITLE_2: 'Crea un nuovo file',
                            TEXT_2: '<p>Come scegliere correttamente formato, colore e impostazioni di abbondanza fin dall’inizio.</p>' +
                                '<p>Creare un nuovo documento in Adobe Illustrator è molto semplice. Tuttavia, per ottenere risultati di stampa ottimali, ci sono determinati dettagli che dovresti tenere a mente. Li illustreremo nel seguente tutorial.</p>' +
                                '<p>Per iniziare, apri Adobe Illustrator e seleziona <b>File → Nuovo...</b></p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_1.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Si aprirà una nuova finestra, permettendoti di selezionare le impostazioni per il tuo nuovo documento. Prima di tutto, definisci il titolo del tuo documento nel campo Nome, e seleziona <b>Stampa</b> nel campo <b>Profilo</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_2.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Poi, devi definire le dimensioni del documento.</p>' +
                                '<p>Come esempio, useremo biglietti da visita con un formato di 85 mm x 55 mm.</p>' +
                                '<p>Dal menu a tendina <b>Unità</b>, puoi scegliere l’unità di misura che vorresti usare. Normalmente, si usano mm o cm. Nei campi <b>Larghezza</b> e <b>Altezza</b> imposta larghezza e altezza con le quali vorresti stampare il documento.</p>' +
                                '<p>Devi anche impostare l’abbondanza di 5 mm. Questo garantisce che ci sia un margine di sicurezza durante il processo di taglio.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_3.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Infine, controlla in <b>Opzioni Avanzate</b> che il metodo colore sia impostato a CMYK, e che gli effetti di rasterizzazione siano impostati come <b>Alta (300 ppi)</b>. Queste impostazioni aiutano a massimizzare la qualità del prodotto di stampa.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_4.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Seleziona il profilo <b>Colore CMYK</b></p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_5.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Il tuo documento è adesso impostato correttamente, e puoi iniziare a lavorare!</p>',
                            TITLE_3: 'Usa immagini ad alta risoluzione',
                            TEXT_3: '<p>Quando usi immagini nella tua tavola di disegno, dovresti farlo molto attentamente. Solo perché le immagini appaiono bene sul monitor, questo non significa necessariamente che appariranno bene sul prodotto finale stampato.</p>' +
                                '<p><b>Importante: tutte le immagini devono essere in metodo colore CMYK e non in RGB.</b></p>' +
                                '<p>Le immagini possono essere di due tipi: immagini bitmap e immagini vettoriali.</p>' +
                                '<p>Le immagini bitmap sono costituite da una serie di piccoli punti chiamati pixel. Questi pixel sono organizzati insieme in un pattern che forma un’immagine. Quando zoommi sull’immagine ad un certo punto inizierai a vedere i singoli pixel che contribuiscono a formare l’immagine. A confronto, le immagini vettoriali non sono basate su pixel, ma su formule matematiche che creano determinate linee e curve. Quando fai lo zoom su un’immagine vettoriale, questa è altrettanto precisa come nella sua dimensione originale.</p>' +
                                '<p>Per assicurarti della nitidezza delle immagini bitmap dovresti controllare la risoluzione (DPI) delle immagini nel quadro di disegno. L’unità di misura DPI (dots per inch) misura la quantità di pixel per pollice.</p>' +
                                '<p>Un DPI basso porterà ad un’immagine di bassa qualità e sfocata.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_20.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Qui sopra a destra puoi vedere un’immagine Bitmap con una risoluzione di 72 pixel per pollice, mentre sulla sinistra vediamo un’altra immagine con 300 pixel per pollice. Puoi vedere chiaramente che la qualità della foto a destra risente della bassa risoluzione di 72 dpi.</p>' +
                                '<p>Per la stampa, assicurati che le tue immagini bitmap abbiano una risoluzione di almeno 300 DPI per garantire risultati di stampa ottimali.</p>' +
                                '<p>Puoi verificare la risoluzione delle immagini bitmap nel tuo quadro di lavoro in questo modo: Apri il quadro immagine in Adobe Illustrator e vai a <b>Finestra → Informazioni</b> sul documento.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_21.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Per verificare la risoluzione delle immagini, vai a <b>Informazioni Documento</b>.</p>' +
                                '<p>Così si apre una finestra grazie alla quale puoi controllare le informazioni del documento nel dettaglio. Clicca sul bottone <b>Più opzioni</b> in alto a destra della finestra, e seleziona l’opzione <b>Immagini Incorporate</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_22.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Poi, seleziona l’immagine che vorresti controllare. La finestra delle informazioni sul documento mostrerà quindi la risoluzione dell’immagine sotto Risoluzione.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_23.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Selezione l’immagine che vorresti controllare, e controlla se la risoluzione è a 300 o di più. Assicurati che la risoluzione sia almeno 300x300 pixels/inch. Se la risoluzione è minore, l’immagine potrebbe apparire pixellata sul prodotto di stampa.</p>' +
                                '<p>Nota bene: non c’è modo di trasformare un’immagine in bassa risoluzione in un’immagine in alta risoluzione. E’ necessario acquisire l’immagine bitmap in una risoluzione maggiore e sostituirla nel quadro di lavoro.</p>',
                            TITLE_4: 'Converti i fonts in tracciati',
                            TEXT_4: '<p><b>Come usare i font</b></p>' +
                                '<p>I font sono set di caratteri di scrittura che puoi installare sul tuo computer. Quando vengono installati, puoi usarli in qualsiasi file o tipo di lavoro che crei con il tuo device.</p>' +
                                '<p>Nonostante ciò, aprire quei file su un altro computer potrebbe presentare problemi qualche volta. I problemi che si potrebbero presentare riguardano l’aspetto del testo se il font non è stato ancora installato sul computer in questione. In questo modo, il computer non ha le informazioni richieste per mostrare e modificare quei testi correttamente.</p>' +
                                '<p>Per evitare questo tipo di problemi, è importante che tu converta quei font nel tuo quadro di lavoro in curve. In questo tutorial ti mostreremo come fare.</p>' +
                                '<p><span class="highlighted-yellow">Salvare file di produzione grafica come PDF/X3:2002 elimina la necessità di conversione manuale in curve.</span></p>' +
                                '<p><b>Come convertire font in curve</b></p>' +
                                '<p><span class="highlighted-yellow"><b>IMPORTANTE</b> prima di continuare: assicurati di salvare il documento originale con un nome diverso, perché una volta che questo processo è completato non sarai più in grado di modificare il testo.</span></p>' +
                                '<p>Per prima cosa, devi identificare i font che vuoi convertire in curve, e trovare la loro posizione nel quadro di lavoro. Lo strumento "Trova Font" ti permetterà di fare esattamente questo.</p>' +
                                '<p>Nel Menu in alto, clicca su <b>Testo → Trova Font</b>. La finestra che si apre elencherà tutti i diversi font nel tuo documento.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_26.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Usa lo strumento per trovare tutti i font nel tuo documento Per rintracciare i font nel tuo quadro di lavoro, seleziona gli elementi della lista uno per uno e clicca <b>Trova</b> per ognuno per evidenziarli nel quadro di lavoro. Trova ognuno di loro cliccando su Trova.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_27.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Una volta che tu sai dove sono i font, selezionali singolarmente. Per convertire i font selezionati in curve, vai al Menu in alto e clicca su <b>Carattere → Crea contorno</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_28.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Seleziona ogni font singolarmente e clicca su Crea contorno</p>' +
                                '<p>Questo processo converte i tuoi font in curve evitando modifiche nell’aspetto del file quando si cambia computer. Con un paio di clic, puoi convertire tutti i font del tuo documento per una compatibilità perfetta.</p>' +
                                '<p>Ricorda che convertire i font in curve ti impedirà di modificare ulteriormente il testo.</p>',
                            TITLE_5: 'Salva un PDF in formato PDF-X3:2002',
                            TEXT_5: '<p>Prima di salvare il tuo file grafico come PDF come descritto in questo tutorial, assicurati che i margini di sicurezza e l’abbondanza siano compatibili con i nostri requisiti.</p>' +
                                '<p>Dopo esserti assicurato che il tuo file sia pronto per la stampa, clicca su <b>File → Salva</b> come dal Menu in alto.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_29.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Sotto Formato, scegli Adobe PDF (pdf), e poi clicca su <b>Salva</b>. Si aprirà una nuova finestra per le specifiche del PDF.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_30.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>In questa finestra, imposta il campo Adobe PDF Preset con l’opzione <b>Stampa in alta qualità</b>. In alcune versioni di Adobe Illustrator, questo menu potrebbe mostrare soltanto un’opzione <b>Modificato</b>. In ogni caso, imposta il modello standard a PDF/X-3:2002, e spunta la voce <b>Ottimizza per Visualizzazione web veloce</b>, e <b>Visualizza PDF</b> dopo aver salvato le opzioni nel pannello delle opzioni.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_31.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p><i>Scegli lo standard PDF/X-3:2002 e imposta Stampa in alta qualità.</i></p>' +
                                '<p>A quel punto, seleziona <b>Margini e Abbondanze</b> sulla parte sinistra, e spunta le caselle delle voci <b>Segni di taglio</b> e <b>Margini di registrazione</b> nel <b>pannello Margini</b>, per assicurarti che il prodotto di stampa venga tagliato correttamente. Nel <b>pannello Abbondanze</b>, spunta l’opzione <b>Usa impostazioni di abbondanza</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_32.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Dopo esserti assicurato ancora una volta che tutte le impostazioni siano state selezionate come descritto qui sopra, clicca su Salva PDF.</p>',
                            TITLE_6: 'Verifica linee sottili e fonts',
                            TEXT_6: '<p>Con la stampa offset, ci sono inevitabilmente variazioni di 2 o 3 decimi di millimetri per ogni elemento che va in stampa. Per elementi grandi e medi, questo cambiamento non verranno notati. Ma per oggetti più piccoli e per linee e testi sottili, di dimensioni minori di 8 punti, la differenza potrebbe diventare più visibile e potrebbe portare a una perdita di leggibilità e a sfocatura.</p>' +
                                '<p>Per evitare complicazioni, suggeriamo di evitare di usare più di 2 colori per una composizione di elementi piccoli.</p>' +
                                '<p>Per esempio, guarda alla lettera seguente (zoommata):</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_36.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p><i>Differenza con font zoommato con uno e 4 colori</i></p>' +
                                '<p>Comporre usando 4 colori può seriamente minare la leggibilità del testo, mentre solo un colore non influenza la qualità della lettura.</p>' +
                                '<p>Per linee sottili (con uno spessore inferiore ai 2 punti), si applica la stessa logica. Per esempio, vuoi stampare una linea marrone. Tre colori sono necessari per creare il marrone: ciano, magenta e giallo. Di conseguenza, potrebbe esserci un errore con la stampa se la linea è sottile: se la linea è più sottile di 2 punti, la tua linea potrebbe finire con cinque diversi colori invece di una sola (come desiderato).</p>' +
                                '<p>Nel nostro esempio, la linea dovrebbe essere o in un solo colore (esempio: nero) se sottile, o spessa almeno di 3-4 punti se composta da più di 2 colori.</p>' +
                                '<p><span class="highlighted-yellow">Possiamo invece stampare linee "K=100" o grayscale di K, fino a un minimo di 0.5 punti equivalenti a 0.175mm</span></p>' +
                                '<p><span class="highlighted-yellow">Riguardo i font, anche un corpo 6 è stampabile molto bene, con l\'accortezza che i testi neri siano K=100 o in un grayscale di solo K</span></p>',
                            TITLE_7: 'Imposta il documento in quadricromia',
                            TEXT_7: '<p>Idealmente, dovresti impostare il tuo documento in CMYK quando hai iniziato a lavorare sul tuo file. In caso non lo avessi ancora fatto, segui questo tutorial per cambiare il tuo file in metodo colore CMYK.</p>' +
                                '<p>Durante il processo di stampa offset, usiamo CMYK, che è un modello cromatico in cui tutti i colori sono descritti come un mix dei 4 colori di processo (quadricromia): ciano, magenta, giallo e nero.</p>' +
                                '<p>Per assicurarti che il tuo documento sia in metodo colore CMYK, puoi aprire <b>Finestra → Informazioni Documento</b> e verificare che il file sia in metodo colore CMYK.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_24.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Apri <b>Informazioni Documento</b> per verificare che il tuo lavoro sia impostato in CMYK.</p>' +
                                '<p>Se il documento non è in CMYK, vai alla voce <b>File → Metodo colore documento → CMYK</b> in modo da cambiare il metodo colore.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_25.jpg" alt="img" class="w-50-res mb-2">',
                            TITLE_8: 'Imposta correttamente i grigi',
                            TEXT_8: '<p>Grigio è un colore intermedio tra nero e bianco, e deve essere trattato molto attentamente. Nel metodo CMYK, il grigio viene ottenuto aggiungendo nero (K). Per comporre un tono di di grigio, assicurati di impostare il tuo colore nero (K) a una certa percentuale tra 5% e 100%. Evitare di usare gli altri tre colori (impostando ciano, magenta e giallo a 0%). Usare ciano, magenta e giallo per comporre toni di grigio causerebbe risultati di stampa non ottimali.</p>' +
                                '<p>Gli esempi qui sotto mostrano la modalità corretta e scorretta di impostare i colori grigi.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_33.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>I colori grigi K possono variare tra 5% e 100%, ma C, M e Y devono essere impostati a 0.</p>' +
                                '<p><span class="highlighted-yellow">È sconsigliato scendere al disotto del 5% di nero (K).</span></p>' +
                                '<p>La parte sinistra mostra come impostare correttamente colori grigi. Usando solo la scala di nero assicura che la aree e gli elementi grigi nel quadro di lavoro vengano settati correttamente per la stampa.</p>' +
                                '<p>La parte destra illustra la modalità sbagliata. Usare colori (CMY) e nero (K) sovraccaricherà il grigio. Inoltre, questo aggiunge complessità dato che il grigio non sarà più composto da un colore, ma da colori multipli.</p>' +
                                '<p>Per impostare aree nere e elementi di stampa, consulta la sezione "Imposta correttamente il nero"</p>',
                            TITLE_9: 'Imposta correttamente il nero',
                            TEXT_9: '<p>Il nero è un colore largamente usato nel campo del graphic design, perché le sue caratteristiche possono essere molto utili in termini di contrasto, peso e forza. Tuttavia, il nero può anche causare problemi durante la stampa, dovuti principalmente a errori nel settaggio dei programmi di editing.</p>' +
                                '<p>Per testi, linee sottili ed elementi piccoli, assicurati sempre che usi nero puro, ossia Nero (K) impostato al 100%, mentre gli altri tre colori (C, M e Y) sono impostati a 0%.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_34.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Per aree ed elementi più grandi, usa una forma composita di nero, ossia Ciano (C) = 30%, Nero (K) = 100%, Magenta (M) = 30% e Giallo (Y) = 0%.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_35.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Queste istruzioni devono essere applicate in qualsiasi programma di editing (Adobe Photoshop, Adobe Indesign, ecc.), anche se sono state originariamente impostate in Adobe Illustrator. Questo perché le palette cromatiche funzionano nello stesso modo in qualsiasi programma.</p>',
                            TITLE_10: 'Impostare i file per i pieghevoli',
                            TEXT_10: '<p>Nell’impostare un file grafico che contiene piegature, è importante essere coscienti della necessità dei margini di sicurezza su ogni lato delle linee di piega.</p>' +
                                '<p>Data la natura meccanica di una piegatrice, la reale linea di piega sui tuoi prodotti finiti potrebbe variare da 2 a 3 mm da ogni lato della linea. Conseguentemente, se non includi un margine di sicurezza da ciascuno dei lati della linea di piega, la piega potrebbe finire sul testo, sulle immagini o su altri contenuti.</p>' +
                                '<p>Suggeriamo sempre di lasciare un margine di 5 mm su ogni lato della linea di piega per assicurarti che il tuo contenuto sia 100% al sicuro.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_37.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Lascia un margine di sicurezza di 5 mm su ogni lato della linea di piega.</p>' +
                                '<p>Per riassumere, se vuoi ricevere il tuo prodotto stampato nella sua forma completa, senza errori o deviazioni dal design originale, lasciare un margine di sicurezza da ogni lato della linea di piega è fondamentale!</p>' +
                                '<p>Se hai domande dopo aver letto la nostra sezione di assistenza, o vorresti la nostra assistenza nel lasciare un margine di sicurezza appropriato, sentiti libero di contattarci in qualsiasi momento.</p>',
                            TITLE_11: 'Impostare i file per le cartelline',
                            TEXT_11: '<p>Per impostare correttamente il file grafico per una cartellina è necessario scaricare il relativo template e aprirlo con Adobe Illustrator.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_38.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>È fondamentale mantere inalterato il livello fustella, pertanto per comodità consigliamo di bloccarlo e di creare un nuovo livello nominandolo "Grafica".</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_39.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Su questo nuovo livello andranno posizionati gli elementi grafici sempre ad una distanza di sicurezza di almeno 5 mm dalle linee di taglio.</p>' +
                                '<p>Terminata l\'elaborazione grafica salvare il correttamente il PDF in X-3:2002.</p>',
                            TITLE_12: 'Imposta il formato',
                            TEXT_12: '<p>Prima di caricare il tuo file, dovresti assicurarti che il formato del file e il numero delle pagine corrispondano al prodotto che hai ordinato sul sito:</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_6.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>In questo tutorial, ti mostreremo come controllare o cambiare le dimensioni del tuo file e il numero delle pagine.</p>' +
                                '<p>Prima, selezione lo strumento <b>Tavola Disegno</b> nella barra del menu:</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_7.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Dopo aver cliccato sul pulsante dello strumento Tavola Disegno, il tuo schermo cambierà nella visualizzazione Tavola Disegno. Questa visualizzazione ti permette di controllare e, se necessario, modificare le dimensioni della tua tavola da disegno.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_8.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Dopo esserti assicurato che il formato sia corretto, esci dallo strumento Tavola da disegno.</p>',
                            TITLE_13: 'Imposta margini e abbondanze',
                            TEXT_13: '<p>Per ottenere risultati di stampa ottimali, assicurati che i tuoi file abbiano 5 mm di abbondanza e 5 mm di margine di sicurezza. Questo passaggio è molto importante, perché durante il processo di taglio, potrebbe accadere che il taglio sia eccessivo all’interno (per questo motivo è consigliato un margine di sicurezza) o all’esterno (per questo motivo serve l’abbondanza).</p>' +
                                '<p>Il margine di sicurezza garantisce che nessuno contenuto venga tagliato fuori dal file grafico. L’abbondanza garantisce che non ci sarà nessun bordo bianco ai marigni del prodotto.</p>' +
                                '<p><b>Impostare l\'abbondanza</b></p>' +
                                '<p>Dopo aver aperto il tuo file, clicca sulla voce <b>Imposta documento</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_9.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Si aprirà una nuova finestra, attraverso la quale puoi impostare l’abbondanza a 5 mm su tutti i lati del file.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_10.jpg" alt="img" class="w-50-res mb-2">' +
                                '<p>Dopo aver cliccato su OK, un contorno rosso intorno al file grafico indicherà fino a che punto gli elementi grafici devono abbondare, per raggiungere un’abbondanza sufficiente di 5 mm.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_11.jpg" alt="img" class="w-75-res mb-2">' +
                                '<img src="../assets/images/press-up/linee-guida/guida_12.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Senza abbondanza, informazioni importanti potrebbero essere esclusi. Con l’abbondanza, qualsiasi informazione sul tuo file è al sicuro.</p>' +
                                '<p><b>Impostare i margini di sicurezza</b></p>' +
                                '<p>Per mostrare margini di sicurezza in Adobe Illustrator, devi usare il seguente metodo, perché margini di sicurezza non sono supportati automaticamente in Illustrator. Prima di tutto, seleziona lo <b>Strumento Rettangolo</b> e clicca in un punto qualsiasi dello schermo.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_13.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Si aprirà una nuova finestra, dove puoi inserire le dimensioni del rettangolo. Imposta le dimensioni del rettangolo a 10mm meno larghe e 10mm meno alti del file grafico, per assicurare un margine di sicurezza di 5 mm su tutte le pagine.</p>' +
                                '<p>Per esempio, se i tuoi biglietti da visita hanno un formato di 85mm x 55mm, imposta le dimensioni del rettangolo a 75mm x 45mm.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_14.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Imposta le dimensioni del rettangolo a 5 mm in meno rispetto alla larghezza e 5 mm in meno rispetto all’altezza del tuo file grafico.</p>' +
                                '<p>Clicca OK per creare il rettangolo. Poi, centralo sulla tavola di disegno (<b>Finestra → Allinea</b>).</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_15.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>Nell’angolo superiore destro di questa finestra, clicca su <b>Più opzioni</b> e poi seleziona <b>Mostra opzioni</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_16.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Allinea il rettangolo alla tua tavola di disegno cliccando su <b>Allinea e centra orizzontalmente e verticalmente</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_17.jpg" alt="img" class="w-25-res mb-2">' +
                                '<p>Mentre selezioni il rettangolo, clicca con il tasto destro in un punto qualsiasi dello schermo e seleziona <b>Crea Guide</b>.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_18.jpg" alt="img" class="w-75-res mb-2">' +
                                '<p>A questo punto, abbondanza e margini di sicurezza sono impostati correttamente. Assicurati che tutti gli elementi grafici si estendano fino al bordo rosso, e che nessuna informazione si trovi tra il bordo rosso e il bordo azzurro, perché quell’informazione potrebbe essere esclusa durante il processo di taglio.</p>' +
                                '<img src="../assets/images/press-up/linee-guida/guida_19.jpg" alt="img" class="w-50-res mb-2">'
                        }
                    }
                },
                FOR_A_EURO: {
                    TITLE: 'Provaci a 1 €',
                    TEXT: 'Mettiamo a tua disposizione i nostri consulenti per aiutarti a trasformare le tue idee in realtà',
                    ICON: {
                        ICON_1: 'Scegli il prodotto',
                        ICON_2: 'Registrati',
                        ICON_3: 'Carica il tuo file',
                        ICON_4: 'Ricevi il prodotto',
                    }
                }
            },
            STEPPER: {
                NAMES: {
                    FIRST_STEP: 'Dati Anagrafici',
                    SECOND_STEP: 'Documenti',
                    THIRD_STEP: 'Titolare Effettivo',
                    FOURTH_STEP: 'Dati Contabili',
                    FIFTH_STEP: 'Dati firmatari'
                },
                PERSONAL_DATA_FIELDS: {
                    COMPANY_NAME: 'Nome azienda',
                    EMAIL: 'E-mail',
                    PEC: 'Email PEC',
                    VAT: 'P.IVA',
                    CAP: 'CAP',
                    COUNTRY: 'Paese',
                    CFIS: 'CFIS',
                    LEGAL_FORM: 'Forma Giuridica',
                    PROVINCE_REGION_BUSINESS: 'Provincia reg. imprese',
                    REA: 'REA',
                    DATE_INCORPORATION: 'Data costituzione',
                    ATECO_NACE: 'Ateco/nace',
                    ATECO_DESCRIPTION: 'Descrizione Ateco',
                    ACTIVITY_DESCRIPTION: 'Descrizione Attività',
                    NAME: 'Nome',
                    SURNAME: 'Cognome',
                    ADDRESS: 'Indirizzo',
                    HOUSE_NUMBER: 'Numero civico',
                    CITY: 'Città',
                    PROVINCE: 'Provincia',
                    DATE_OF_BIRTH: 'Data di nascita',
                    NATIONALITY: 'Nazionalità',
                    COUNTRY_OF_RESIDENCE: 'Paese di residenza',
                    COUNTRY_CODE: 'Prefisso internazionale',
                    STATE_CODE: 'Codice dello stato',
                    PHONE: 'Telefono',
                    CONTACTS: 'Contatti',
                    RECIPIENT: 'Destinatario',
                    LOCATION: 'Località',
                    TAX_ID_CODE: 'Codice fiscale',
                    DESCRIPTION: 'Descrizione indirizzo',
                    DIFFERENT_ADDRESS: 'Indirizzo di spedizione diverso da fatturazione',
                },
                CHECKBOX: {
                    SHIPPING_BILLING_MATCH: 'L\'indirizzo di spedizione e di fatturazione coincidono',
                    MAIN_ADDRESS: 'Indirizzo di spedizione principale'
                },
                SIGNATORY: {
                    SIGNATORY_DATA: 'Dati firmatario',
                    NAME: 'Nome',
                    SURNAME: 'Cognome',
                    EMAIL: 'Email',
                    PHONE_NUMBER_NATIONALITY: 'Nazionalità del numero di telefono',
                    PHONE: 'Telefono',
                    CLICK_ON_ADD: 'Clicca su "Aggiugi" per inserire un nuovo firmatario',
                },
                NO_ELEMENTS_TO_SHOW: {
                    CLICK_TO_INSERT: 'Clicca il pulsante "aggiungi indirizzo" per iniziare',
                    NO_ACCOUNT_DATA: 'Completa i tuoi dati account',
                    CLICK_TO_INSERT_REPORTING: 'Clicca il pulsante "nuova segnalazione" per iniziare',
                },
            },
            OFFERS: {
                OFFERS: 'Offerte',
                PRESSUP_OFFERS: 'Le offerte di pressup'
            },
            SEARCH: {
                RESULT: 'Ecco i risultati della tua ricerca per',
                NO_RESULT: 'Nessun risultato trovato',
                SEARCH: 'Cerca',
                RESULT_SHOWED: 'Risultati mostrati {{from}} - {{to}} di {{total}} risultati'
            },
            TRUSTPILOT: {
                SHOW_MORE_REVIEWS: 'Visualizza altre recensioni',
                REVIEWS_TITLE: 'Stampa Digitale Online: personalizzazione e consegna rapida - PressUP',
            }
        },
        COMMUNICATION: {
            LOG_IN_EMAIL: 'Accedi alla tua email',
            EMAIL_SENT: 'Per completare la registrazione accedi alla tua email e clicca sul link che ti abbiamo inviato.',
            WE_ARE_ALMOST_THERE: 'Ci siamo quasi...',
            VERIFYING_INFO: 'Stiamo verificando le tue info...',
            DID_NOT_RECEIVE_EMAIL: 'Non hai ancora ricevuto l\'e-mail?'
        },
        MODAL: {
            FIELD_UPDATED_CORRECTLY: 'Campi aggiornati correttamente',
            SOMETHING_WENT_WRONG: 'Qualcosa è andato storto',
            WRONG_CREDENTIAL: 'Le credenziali inserite sono errate',
            INCORRECT_CREDENTIALS_TEXT: 'Ti consigliamo di verificarle e reinserirle correttamente.',
            COMMUNICATION_ERROR: 'Ci dispiace si è verificato un errore. Ti invitiamo a riprovare più tardi.',
            CREDENTIALS_ALREADY_USED: 'Credenziali già in uso',
            VALIDATION_SUCCESS: 'Validazione effettuata con successo',
            GO_TO_LOGIN_FROM_VALIDATION: 'Portami alla login',
            PASSWORD_UPDATED: 'Password aggiornata con successo',
            TOKEN_EXPIRED: 'Token scaduto, riprova',
            ADD_NOTIFICATION_SUCCESS: 'Notifica aggiunta con successo',
            ERROR_DELETING_ITEM: 'Errore durante l\'eliminazione dell\'item',
            ADDRESS_SAVED: 'Indirizzo salvato correttamente',
            PROBLEM_ADDRESS_SAVED: 'Errore nel salvataggio dell\'indirizzo. Ricontrollare i campi.',
            PROBLEM_ADDRESS_MODIFIED: 'Errore nella modifica dell\'indirizzo. Ricontrollare i campi.',
            ADDRESS_MODIFIED: 'Indirizzo modificato correttamente',
            DELETE_ADDRESS: 'Eliminare l\'elemento selezionato?',
            COMPLETE_ACCOUNT: 'Completa la registrazione inserendo i dati richiesti in dati account',
            MEX_SEND_CURR: 'Messaggio inviato correttamente',
            TOTAL_AMOUNT_MULTI_SUBJECT: 'Il supplemento di prezzo è di ',
            UPLOAD_FILE_SUCCESS: 'Il caricamento è stato eseguito correttamente',
            UPLOAD_FILE_ERROR: 'Il servizio di caricamento non ha funzionato correttamente, riprova più tardi',
            ORDER_ITEM_ID_ERROR: 'Non è possibile richiedere assistenza per questa lavorazione',
            CAPTCHA_TITLE: 'Verifica di sicurezza',
            CAPTCHA_SUBTITLE: 'Per continuare inserisci il testo che vedi nell\'immagine.',
            SEND_MAIL_ERROR: 'E-mail non inviata correttamente.'
        },
        TOAST: {
            SERVER_ERROR: 'Si è verificato un problema durante il recupero dei dati. Per favore, riprova più tardi.',
            GENERAL_CALL_ERROR: 'Si è verificato un problema nel recupere i dati di {{call}}',
            GENERAL_CALL_ERROR_SIMPLE: 'Si è verificato un problema nel recupere i dati',
            SUCCESS_DELETING: '{{item}} cancellato correttamente',
            OPERATION_SUCCESS: 'Operazione effettuata con successo'
        },
        PREVENTIVATOR: {
            ADD_CART: 'Aggiungi al carrello',
            DOWNLOAD_TEMPLATE: 'Scarica il template',
            SUMMARY_PROCESSING: 'Riepilogo lavorazione',
            QUOTATION_AND_DELIVERY: 'Preventivo e consegna',
            DELIVERY: 'Consegna',
            NET_PROCESSING: 'Netto lavoraz.',
            DISCOUNTED_PRICE: 'Prezzo scontato',
            VAT_22: 'IVA 22%',
            TOTAL_ESTIMATE: 'Tot. preventivo',
            NAME: 'Nome',
            NAME_PROCESSING: 'Nome della lavorazione'
        }
    }
};
